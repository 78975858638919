import {fromJson, getCollectionOffline, URL_BASE} from "./helper";
import store from "../store";


class ServiceFuelStockAuditModel {


    constructor() {

        this.id = null;
        this.fuel_id = null;
        this.fuel = null;
        this.initial_physical_stock = 0;
        this.initial_terminant = 0;
        this.end_terminant = 0;
        this.balance_terminant = 0;
        this.calculated_balance = 0;
        this.audit_result_balance = 0;
        this.current_physical_stock = 0;
        this.system_input = 0;
        this.system_output = 0;
        this.images = null;
        this.start_date = null;
        this.end_date = null;
        this.created_at = null;

    }


    static _module() {
        return URL_BASE + "/service_fuel_stock_audits";
    }

    static fromJson(object) {
        return fromJson((new ServiceFuelStockAuditModel()), object);
    }

    destroyURL() {
        return ServiceFuelStockAuditModel._module() + "/" + this.id + "/destroy"
    }


    static async list(endpoint = '/', data = {}) {
        data.attendance_id = store.getters.attendance.id

        let url = this._module() + endpoint;
        var collection = new Array()
        if (!navigator.onLine){
            return getCollectionOffline('fuel_stock_audit',data.attendance_id, this)
        }
        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return ServiceFuelStockAuditModel.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }
}

export default ServiceFuelStockAuditModel;
