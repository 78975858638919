<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="{'ibox':true, 'float-e-margins':true, 'p-3':$store.getters.isMobile}">
                <div class="ibox-title">
                    <h5 :class="{'text-white':$store.getters.isMobile}">Cadastro de custo</h5>
                </div>
                <div class="ibox-content">

                    <form :method="method" class="form-horizontal" name="frm_cost"
                          autocomplete="off"
                          enctype="multipart/form-data" @submit.prevent="submit">

                        <!-- inicio dos campos -->

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label>Descrição *</label>
                                <input class="form-control" name="description" v-model.lazy="fields.description"
                                       value=""/>
                                <div v-if="errors && errors.description" class="text-danger">{{
                                    errors.description[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Preço (R$) *</label>
                                <input type="number" step="any" name="price"
                                       class="form-control"
                                       v-model="fields.price"/>
                                <div v-if="errors && errors.price" class="text-danger">{{
                                    errors.price[0]
                                    }}
                                </div>
                            </div>


                            <div class="form-group col-md-12">
                                <label for="user_id">Solicitante</label>
                                <v-select @search="onSearch"
                                          :options="itemOptions"
                                          id="user_id"
                                          :reduce="option => option.id"
                                          label="description"
                                          placeholder="Escolha um responsável"
                                          v-model="fields.user_id">
                                    <template slot="no-options">
                                        Pesquise por usuários
                                    </template>
                                </v-select>


                                <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0]
                                    }}
                                </div>
                            </div>


                            <div class="form-group col-md-12">
                                <label>Arquivo </label>
                                <input type="file" class="form-control" name="file" />
                                <div v-if="errors && errors.file" class="text-danger">{{
                                    errors.file[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Data para pagamento</label>
                                <input type="datetime-local" name="due"
                                       class="form-control"
                                       v-model="fields.due"/>
                                <div v-if="errors && errors.due" class="text-danger">{{
                                    errors.due[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Observação</label>
                                <textarea  name="observations"
                                       class="form-control"
                                           v-model="fields.observations"></textarea>
                                <div v-if="errors && errors.observations" class="text-danger">{{
                                    errors.due[0]
                                    }}
                                </div>
                            </div>


                        </div>


                        <button class="btn btn-primary" id="bt_salvar" type="submit">
                            <i class="fa fa-save"></i>
                            Salvar <span v-if="item">Alterações</span>
                        </button>

                        <a @click="$emit('isForm', false)" :class="{'btn':true, 'btn-default':true, 'text-white':$store.getters.isMobile}"  id="ln_listar_form">
                            <i class="fa fa-list-ul"></i>
                            Listar
                        </a>

                        <!-- FIM -->
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select';
    import moment from 'moment'
    import {URL_BASE} from "../../models/helper";
    import UserModel from "../../models/UserModel";
    import Pendency from "../../models/PendencyModel";
    import CostModel from "../../models/CostModel";

    export default {
        components: {vSelect},
        props: {
            item: {
                type: Object,
                required: false
            }
        },
        data() {
            return {

                loaded: true,
                errors: {},
                itemOptions: [],
                fields: new CostModel(),
                method: "post",
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false
                }
            }
        },
        created() {

        },
        methods: {
            moment: moment,
            async onSearch(search, loading) {
                loading(true)
                this.itemOptions = await UserModel.find(search, [this.$store.getters.types.ADMIN]);
                loading(false)
            },
            async submit(newRecord = false) {

                //this.method = this.item ? 'put': 'post';

                let form = document.forms.frm_cost;
                let Data = new FormData(form);

                Data.append("user_id", this.fields.user_id)
                Data.append("attendance_id", this.$store.getters.attendance.id);

                let url = CostModel._module();

                if (this.item) {
                    this.method = 'put';
                    Data.append("id", this.item.id)
                    Data.append("_method", this.method)
                }

                return this.save(url, Data, newRecord);
            },
            save(url, data, newRecord = false) {
                this.errors = {};
                block()

                axios.post(url, data)
                    .then(() => {
                        showMessage('s', 'Salvo com sucesso');
                        this.$emit('isForm', false)

                    })
                    .catch(error => {

                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }


                        if (error.response.status === 401) {
                            showMessage('w', error.response.data.server_error);
                        }

                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors || {};
                            showMessage('w', 'Preencha todos os campos obrigatórios.');
                        }
                    })
                    .finally(() => unBlock())
            },

        },
        mounted() {
            if (this.item) {
                this.fields = this.item;
                this.fields.due =  moment(this.fields.due).format("YYYY-MM-DDTHH:mm")
                this.itemOptions.push(
                    {
                        id: this.item.user.id,
                        description: this.item.user.name
                    }
                )
                this.fields.user_id = this.item.user.id
            }


        }
    }

</script>

<style scoped>

</style>
