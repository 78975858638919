<template>
    <div v-if="!$store.getters.isMobile" class="ibox-content inspinia-timeline">
        <div class="timeline-item">
            <div class="row" v-for="history in $store.getters.histories">
                <div class="col-md-1 date" style="width: 140px;">
                    <i class="fa fa-clock-o"></i>
                    <br>
                    <small class="text-navy"></small>
                </div>
                <div class="col-md-11 content">
                    <p class="m-b-xs"><strong>{{ moment(history.created_at).format('DD/MM/YYYY HH:mm') }}</strong></p>
                    <p>{{ history.description }}</p>
                    <small>por: {{ history.user.name }}</small>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="list-group list-group-card">
            <div class="container-fluid">
                <div v-for="history in $store.getters.histories" :id="'tr-scst-' + history.id"
                     class="card card-custom-table">
                    <div class="card-content">
                        <div class="card-body-content">
                            <div class="card-body">
                                <p class="m-b-xs">
                                    <strong>{{ moment(history.created_at).format('DD/MM/YYYY HH:mm') }}</strong></p>
                                <p>{{ history.description }}</p>
                                <small>por: {{ history.user.name }}</small>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {URL_BASE} from "../models/helper";
import AttendanceHistoryModel from "../models/AttendanceHistoryModel";
import moment from 'moment'

export default {

    name: "AttendanceHistoryComponent",
    data() {
        return {}
    },
    methods: {
        moment: moment,
    },

}

</script>

<style scoped>

</style>
