import {fromJson, URL_BASE} from "./helper";
import store from "../store";


class ArchiveModel {


    constructor() {

        this.id = null;
        this.attendance_id = null
        this.name = null
        this.file = null
        this.filename = null
        this.url_download = null
        this.size = null
        this.extension = null
        this.creator = null
        this.created_at = null
    }


    static _module() {
        return URL_BASE + "/archives";
    }

    static fromJson(object) {
        return fromJson((new ArchiveModel()), object);
    }

    static async destroyURL(file_id) {

        let url = this._module() + '/' + file_id + '/destroy';
        var collection = new Array();

        await axios.get(url, {})
            .then((response) => {
                showMessage('s', "Deletado com sucesso");

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

    static async list(endpoint = '/', data = {}) {
        data.attendance_id = store.getters.attendance.id

        let url = this._module() + endpoint;
        var collection = new Array()
        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return ArchiveModel.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

}

export default ArchiveModel;
