import {fromJson, getCollectionOffline, URL_BASE} from "./helper";

import store from "../store/index"


class ServiceDialysisModel {


    constructor() {

        this.id = null;
        this.automotor = null;
        this.hour_meter = null;
        this.commentary = null;
        this.is_outsourced = false;
        this.outsource_id = null;
        this.outsource = null;
        this.images = [];
        this.created_at = null;

    }


    static _module() {
        return URL_BASE + "/service_dialysis";
    }

    static fromJson(object) {
        return fromJson((new ServiceDialysisModel()), object);
    }

    destroyURL() {
        return ServiceDialysisModel._module() + "/" + this.id + "/destroy"
    }


    static async list(endpoint = '/', data = {}) {
        data.attendance_id = store.getters.attendance.id
        let url = this._module() + endpoint;
        var collection = new Array()

        if (!navigator.onLine){
            return getCollectionOffline('dialysis',data.attendance_id, this)
        }

        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return ServiceDialysisModel.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }
}

export default ServiceDialysisModel;
