<template>
    <div v-if="!$store.getters.isMobile" class="ibox mb-0">
        <div class="ibox-title">
            <h4>Listagem de custos</h4>
            <div class="ibox-tools" v-if="$store.getters.attendance.is_open">
                <a @click="$emit('isForm', true)" class="btn btn-primary ">
                    <i class="fa fa-plus"></i>
                    Cadastrar
                </a>
            </div>
        </div>
        <div class="ibox-content">
            <form method="get" v-on:submit.prevent>
                <div class="input-group">
                    <input type="text"
                           class="form-control"
                           placeholder="Localizar"
                           v-on:keyup.enter="list"
                           v-model="search.description">
                    <div class="input-group-append">
                        <button class="btn btn-block btn-primary" @click="list">
                            <i class="fa fa-search"></i>
                            Pesquisar
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="ibox-content">
            <div :class="{'modal':true,'inmodal':true, 'fade':true}" id="modalPagar" tabindex="-1" role="dialog"
                 aria-hidden="true">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                        <form method="put" class="form-horizontal" name="frm_pagar"
                              autocomplete="off"
                              enctype="multipart/form-data" @submit.prevent="submitPagar">
                            <div class="modal-header">Efetuar Pagamento</div>
                            <div class="modal-body">
                                <label>Data do pagamento</label>
                                <input type="datetime-local" name="payment_date" v-model="payment_date"
                                       class="form-control"/>
                                <div v-if="errors && errors.payment_date" class="text-danger">{{
                                        errors.payment_date[0]
                                    }}
                                </div>
                                <br>
                                <label>Comprovante</label>
                                <input type="file" class="form-control" name="receipt"/>
                                <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
                                <button type="submit" class="btn btn-primary">Confirmar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="table-responsive" style="min-height: 200px;">
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                    <tr>
                        <th style="width: 100px; text-align: center">Ações</th>
                        <th>Descrição</th>
                        <th>Price (R$)</th>
                        <th>Arquivo</th>
                        <th width="20%">Solicitante</th>
                        <th>Prazo para pagamento</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="dataset.length > 0" :id="'tr-cost-' + item.id" v-for="(item, index) in dataset">
                        <td style="text-align: center">
                            <div class="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button"
                                        class="btn btn-default dropdown-toggle"
                                        data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                    Ações
                                </button>
                                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                    <template v-if="$store.getters.attendance.is_open">
                                        <a class="dropdown-item" @click="cost = item" data-toggle="modal"
                                           data-target="#modalPagar">Pagar</a>
                                        <a class="dropdown-item" @click="$emit('isEditing', item)">Editar</a>
                                        <link-destroy-component
                                            :line-id="'tr-cost-' + item.id"
                                            :link=item.destroyURL()>
                                        </link-destroy-component>
                                    </template>
                                </div>
                            </div>
                        </td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.price.toFixed(2) }}</td>
                        <td>
                            <a target="_blank" :href="item.file">
                                <span class="fa fa-download"></span>
                                Anexo
                            </a>
                        </td>
                        <td>{{ item.user.name }}</td>
                        <td>{{ moment(item.due).format("DD/MM/YYYY HH:mm") }}</td>
                        <td>{{ item.status }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <span v-else>
        <div class="topbar topbar-menu topbar-menu-action">
            <div class="container-fluid">
                <div class="menu-action">
                    <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#filter" type="button">
                            <span>
                                <i class="fa fa-filter"></i>
                                Filtrar pesquisa
                            </span>
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <a v-if="$store.getters.attendance.is_open"
                       @click="$emit('isForm', true)"
                       class="btn btn-success" href="#">Cadastrar</a>
                </div>
            </div>
            <div class="container-fluid">
                <div class="filter-collapse collapse" id="filter">
                     <form method="get" v-on:submit.prevent>
                        <div class="form-group">
                            <input type="text" v-on:keyup.enter="list" name="name"
                                   class="form-control"
                                   v-model="search.description"
                                   placeholder="Pesquisar por descrição">
                        </div>
                            <button @click="list" class="btn btn-block btn-success">
                                    <i class="fa fa-search"></i>
                                    Pesquisar
                                </button>
                    </form>
                </div>
            </div>
        </div>
        <div class="list-group list-group-card">
            <div class="container-fluid">
                <div v-if="dataset.length > 0" :id="'tr-scst-' + item.id" v-for="(item, index) in dataset"
                     class="card card-custom-table card-custom-collapse">
                    <div class="card-content">
                        <div class="card-body-content">
                            <div class="card-header">
                                <h6>Descrição</h6>
                            </div>
                            <div class="card-body">
                             {{ item.description }}
                            </div>
                        </div>
                        <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#item-5"
                                type="button">
                            <i class="fa fa-angle-down"></i>
                        </button>
                    </div>
                    <ul class="list-group list-group-table collapse" id="item-5">
                        <li class="list-group-item">
                            <strong>Price (R$):</strong>  {{ item.price.toFixed(2) }}
                        </li>
                          <li class="list-group-item">
                            <strong>Arquivo:</strong>
                              <a target="_blank" :href="item.file">
                                    <span class="fa fa-download"></span>
                                    Anexo
                                </a>
                        </li>
                        <li class="list-group-item">
                            <strong>Solicitante:</strong> {{ item.user.name }}
                        </li>

                        <li class="list-group-item">
                            <strong>Prazo para pagamento:</strong>
                            {{ moment(item.due).format("DD/MM/YYYY HH:mm") }}
                        </li>

                         <li class="list-group-item">
                            <strong>Status:</strong>  {{ item.status }}
                        </li>

                        <li class="list-group-item">
                          Criado em:  {{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}
                        </li>
                        <li class="list-group-item">
                            <a @click="$emit('isEditing', item)">
                                <i class="fa fa-pencil"></i>
                                Editar
                            </a>
                        </li>
                        <li class="list-group-item">
                            <link-destroy-component
                                :dropdown="false"
                                :danger="true"
                                :line-id="'tr-scst-' + item.id"
                                :link=item.destroyURL()>
                                                    </link-destroy-component>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import moment from 'moment'
import {destroyURL} from "../../models/helper";
import Pendency from "../../models/PendencyModel";
import UserModel from "../../models/UserModel";
import CostModel from "../../models/CostModel";

export default {
    components: {vSelect},
    props: {
        id: {
            type: String,
            required: false
        },
        isForm: {
            type: Boolean,
            required: true
        },
        fields: {
            type: Object,
            required: false
        }
    },
    data() {
        return {

            loaded: true,
            errors: {},
            method: 'POST',
            itemOptions: [],
            selected: 0,
            dataset: [],
            search: {},
            usersAdmin: [],
            payment_date: null,
            cost: {}
        }
    },
    created() {
        this.getUsers()
        this.payment_date = moment(new Date()).format("YYYY-MM-DDTHH:mm");

    },
    methods: {
        moment: moment,
        async list() {
            let app = this
            this.dataset = await CostModel.list('/', this.search);

            this.dataset.forEach(function (item, index) {
                app.itemOptions[index] = app.usersAdmin

            })
        },
        async getUsers() {
            this.usersAdmin = await UserModel.find('', [this.$store.getters.types.ADMIN]);
        },
        async submitPagar(newRecord = false) {

            let form = document.forms.frm_pagar;
            let Data = new FormData(form);

            let url = CostModel._module() + '/pay';
            Data.append("id", this.cost.id)
            Data.append("_method", 'put')


            return this.save(url, Data, newRecord);
        },
        save(url, data, newRecord = false) {
            this.errors = {};
            block()

            axios.post(url, data)
                .then(() => {
                    showMessage('s', 'Salvo com sucesso');
                    this.list()


                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(function () {
                    unBlock();
                    $('#modalPagar').modal('hide')
                })
        },
    },
    mounted() {
        this.list()
    }
}
</script>
<style scoped>
.vs__search {
    height: 60px;
}
</style>
