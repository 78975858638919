<template>
    <div>
        <!--  Attendance-->
        <slot data-module="attendance">
            <attendance-list-component v-if="pages.attendance.list"
                                       v-on:attendance-show="attendanceDetails"
                                       v-on:attendance-new="setPage('attendance.form')">

            </attendance-list-component>
            <attendance-form-component v-if="pages.attendance.form">

            </attendance-form-component>
            <attendance-component v-if="pages.attendance.details && details.attedance"
                :user='{&quot;id&quot;:6,&quot;name&quot;:&quot;Desenvolvedor&quot;,&quot;email&quot;:&quot;desenvolvedor@gmail.com&quot;,&quot;image&quot;:&quot;&quot;,&quot;document_number&quot;:&quot;&quot;,&quot;gender&quot;:&quot;&quot;,&quot;birth&quot;:&quot;&quot;,&quot;phone1&quot;:&quot;&quot;,&quot;phone2&quot;:&quot;&quot;}'
                :attendance="details.attedance"
                :laboratory_test="{&quot;POSITIVE&quot;:&quot;Positivo&quot;,&quot;NEGATIVE&quot;:&quot;Negativo&quot;}"
                :visual_aspect="{&quot;PURE&quot;:&quot;L\u00edmpido e isento de impurezas&quot;,&quot;INPURE&quot;:&quot;N\u00e3o conforme&quot;}"
                :pending_status="{&quot;PENDING&quot;:&quot;Pendente&quot;,&quot;DOING&quot;:&quot;Fazendo&quot;,&quot;DONE&quot;:&quot;Conclu\u00eddo&quot;}"
                :tab_active="''"
                :types="{&quot;ADMIN&quot;:1,&quot;CLIENT&quot;:2,&quot;OPERATOR&quot;:3,&quot;EMPLOYEE&quot;:4}"
                :url="'/'"
                :is_mobile='true'>
            </attendance-component>
        </slot>
    </div>
</template>

<script>

export default {

    name: "SPAComponent",
    components: {},
    data() {
        return {
            pages: {
                attendance: {
                    list: false,
                    form: false,
                    details: false,
                }
            },
            details: {
                attedance: null
            }
        }
    },
    mounted() {
        this.pages.attendance.list = true;
    },
    methods: {
        setPage(route) {
            Object.entries(this.pages).forEach((el) => {
                Object.entries(this.pages[el[0]]).forEach((page) => {
                    this.pages[el[0]][page[0]] = false
                })
            })
            var partials = route.split(".");
            this.pages[partials[0]][partials[1]] = true

        },
        async attendanceDetails(attendance) {

            let client = await getById(attendance.client_id, 'clients')

            attendance["client"] = client;
            attendance["creator"] = {id: null, name: 'User logado'};
            attendance["is_open"] = true;
            attendance["id"] = attendance._id;
            attendance["date_hour_start"] = attendance.date_hour_start + "T00:00:00";
          /*  await this.$store.commit('attendance', attendance)*/

            this.details.attedance =  attendance

            this.setPage('attendance.details')
        },

    }

}

$(document).ready(() => {
    $('.vs__selected').addClass('selected-m')
})
</script>
<style>
.v-select {
    background: #fff !important;
    border-radius: 10px;
    padding: 5px;
}
</style>
