<template>
    <div v-if="!$store.getters.isMobile" class="row">
        <div class="col-lg-12">
            <div class="ibox mb-0">
                <div class="ibox-title">
                    <h4>Listagem de pendências</h4>
                    <div class="ibox-tools" v-if="$store.getters.attendance.is_open">
                        <a @click="$emit('isForm', true)" class="btn btn-primary ">
                            <i class="fa fa-plus"></i>
                            Cadastrar
                        </a>
                    </div>
                </div>
                <div class="ibox-content">
                    <form method="get" v-on:submit.prevent>
                        <div class="input-group">
                            <input type="text" v-on:keyup.enter="list" name="name"
                                   class="form-control"
                                   v-model="search.tank_description"
                                   placeholder="Localizar">
                            <div class="input-group-append">
                                <button @click="list" class="btn btn-block btn-primary">
                                    <i class="fa fa-search"></i>
                                    Pesquisar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="ibox-content">
                    <div class="table-responsive" style="min-height: 200px;">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th style="width: 100px; text-align: center">Ações</th>
                                <th>Descrição</th>
                                <th width="20%">Responsável</th>
                                <th>Data início</th>
                                <th>Data entrega</th>
                                <th>Anexo</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="dataset.length > 0" :id="'tr-scst-' + item.id" v-for="(item, index) in dataset">
                                <td style="text-align: center">
                                    <div class="btn-group" role="group">
                                        <button id="btnGroupDrop1" type="button"
                                                class="btn btn-default dropdown-toggle"
                                                data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                            Ações
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                            <template v-if="$store.getters.attendance.is_open">
                                                <a class="dropdown-item"
                                                   @click="$emit('isEditing', item)">Editar
                                                </a>
                                                <link-destroy-component
                                                    :line-id="'tr-scst-' + item.id"
                                                    :link=item.destroyURL()>
                                                </link-destroy-component>
                                            </template>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ item.description }}</td>
                                <td>
                                    <v-select :options="itemOptions[index]"
                                              id="user_id"
                                              :reduce="option => option.id"
                                              label="description"
                                              @input="setUser(item.user.id, item.id)"
                                              placeholder="Escolha um responsável"
                                              v-model="item.user.id">
                                        <template slot="no-options">
                                            Pesquise por usuários
                                        </template>
                                    </v-select>
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    {{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}
                                </td>
                                <td>{{ moment(item.due).format("DD/MM/YYYY HH:mm") }}</td>
                                <td>
                                    <a target="_blank" :href="item.archive">Anexo</a>
                                </td>
                                <td>{{ $store.getters.pending_status[item.status] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span v-else>
        <div class="topbar topbar-menu topbar-menu-action">
            <div class="container-fluid">
                <div class="menu-action">
                    <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#filter" type="button">
                            <span>
                                <i class="fa fa-filter"></i>
                                Filtrar pesquisa
                            </span>
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <a v-if="$store.getters.attendance.is_open"
                       @click="$emit('isForm', true)"
                       class="btn btn-success" href="#">Nova pendência</a>
                </div>
            </div>
            <div class="container-fluid">
                <div class="filter-collapse collapse" id="filter">
                     <form method="get" v-on:submit.prevent>
                        <div class="form-group">
                            <input type="text" v-on:keyup.enter="list" name="name"
                                   class="form-control"
                                   v-model="search.tank_description"
                                   placeholder="Pesquisar por descrição">
                        </div>
                            <button @click="list" class="btn btn-block btn-success">
                                    <i class="fa fa-search"></i>
                                    Pesquisar
                                </button>
                    </form>
                </div>
            </div>
        </div>
        <div class="list-group list-group-card">
            <div class="container-fluid">
                <div v-if="dataset.length > 0" :id="'tr-scst-' + item.id" v-for="(item, index) in dataset"
                     class="card card-custom-table card-custom-collapse">
                    <div class="card-content">
                        <div class="card-body-content">
                            <div class="card-header">
                                <h6>Descrição</h6>
                                <div>
                                    <strong>Status:</strong>
                                    <span class="text-danger">{{ $store.getters.pending_status[item.status] }}</span>
                                </div>
                            </div>
                            <div class="card-body">
                               {{ item.description }}
                            </div>
                        </div>
                        <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#item-5"
                                type="button">
                            <i class="fa fa-angle-down"></i>
                        </button>
                    </div>
                    <ul class="list-group list-group-table collapse" id="item-5">
                        <li class="list-group-item"><strong>Responsável:</strong> <v-select
                            :options="itemOptions[index]"
                            id="user_id"
                            :reduce="option => option.id"
                            label="description"
                            @input="setUser(item.user.id, item.id)"
                            placeholder="Escolha um responsável"
                            v-model="item.user.id">
                                            <template slot="no-options">
                                                Pesquise por usuários
                                            </template>
                                        </v-select></li>
                        <li class="list-group-item"><strong>Data início:</strong> {{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }} </li>
                        <li class="list-group-item"><strong>Data entrega:</strong> {{ moment(item.due).format("DD/MM/YYYY HH:mm") }} </li>
                        <li class="list-group-item"><strong>Anexo:</strong> <a class="text-info" target="_blank"
                                                                               :href="item.archive">Abrir arquivo</a></li>

                        <li class="list-group-item">
                            <a @click="$emit('isEditing', item)">
                                <i class="fa fa-pencil"></i>
                                Editar
                            </a>
                        </li>
                        <li class="list-group-item">
                            <link-destroy-component
                                :dropdown="false"
                                :danger="true"
                                :line-id="'tr-scst-' + item.id"
                                :link=item.destroyURL()>
                                                    </link-destroy-component>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import moment from 'moment'
import {destroyURL} from "../../models/helper";
import Pendency from "../../models/PendencyModel";
import UserModel from "../../models/UserModel";

export default {
    components: {vSelect},
    props: {
        id: {
            type: String,
            required: false
        },
        isForm: {
            type: Boolean,
            required: true
        },
        fields: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            loaded: true,
            errors: {},
            method: 'POST',
            itemOptions: [],
            selected: 0,
            dataset: [],
            search: {},
            usersAdmin: []
        }
    },
    created() {
        this.getUsers()
    },
    methods: {
        moment: moment,
        async list() {
            let app = this
            this.dataset = await Pendency.list('/', this.search);

            this.dataset.forEach(function (item, index) {
                app.itemOptions[index] = app.usersAdmin

            })
        },
        async getUsers() {
            this.usersAdmin = await UserModel.find('', null);
        },
        setUser(user_id, id) {
            if (user_id != null) {
                block()
                axios.post(Pendency._module() + '/set_user', {
                    user_id: user_id,
                    id: id
                })
                    .then(() => {
                        showMessage('s', 'Alterado com sucesso!');
                    })
                    .catch(error => {
                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }
                    })
                    .finally(() => unBlock())
            }
        }
    },
    mounted() {
        this.list()
    }
}
</script>
<style scoped>
.vs__search {
    height: 60px;
}
</style>
