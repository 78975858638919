<template>
    <div>
        <div v-if="!is_mobile" class="col-lg-12 animated fadeInRight">
            <span v-if="!$store.getters.attendance.is_open" class="badge badge-danger" style="float: right">Atendimento Finalizado</span>
            <div class="tabs-container">
                <ul class="nav nav-tabs">
                    <li :class="{'active':tabs.sobre}">
                        <a data-toggle="tab" @click="tabAction('sobre')" href="#sobre" :aria-expanded="tabs.sobre">
                            <i class="fa fa-plus-square"></i>
                            Sobre
                        </a>
                    </li>
                    <li :class="{'active':tabs.servicos}">
                        <a @click="tabAction('servicos')" data-toggle="tab"
                           href="#servicos"
                           :aria-expanded="tabs.servicos">
                            <i class="fa fa-list-alt"></i>
                            Serviços
                        </a>
                    </li>
                    <li :class="{'active':tabs.pendencias}">
                        <a @click="tabAction('pendencias')" data-toggle="tab"
                           href="#pendencias"
                           aria-expanded="false">
                            <i
                                class="fa fa-hourglass-half"></i>
                            Pendências
                        </a>
                    </li>
                    <li :class="{'active':tabs.mensagens}">
                        <a @click="tabAction('mensagens')" data-toggle="tab"
                           href="#mensagens"
                           aria-expanded="false">
                            <i
                                class="fa fa-commenting-o"></i>
                            Mensagens
                        </a>
                    </li>
                    <li :class="{'active':tabs.arquivos}">
                        <a @click="tabAction('arquivos')" data-toggle="tab"
                           href="#arquivos"
                           aria-expanded="false">
                            <i
                                class="fa fa-file-o"></i>
                            Arquivos
                        </a>
                    </li>
                    <li :class="{'active':tabs.custos}">
                        <a @click="tabAction('custos')" data-toggle="tab" href="#custos"
                           aria-expanded="false">
                            <i
                                class="fa fa-usd"></i>
                            Custos
                        </a>
                    </li>
                    <li :class="{'active':tabs.historico}">
                        <a @click="tabAction('historico')" data-toggle="tab"
                           href="#historico"
                           aria-expanded="false">
                            <i
                                class="fa fa-history"></i>
                            Histórico
                        </a>
                    </li>
                </ul>
                <div class="ibox-content">
                    <div class="tab-content">
                        <div id="sobre" :class="{'tab-pane':true, 'active':tabs.sobre}">
                            <div class="ibox-content">
                                <p class="mb-2">
                                    <strong>Cliente:</strong>
                                    {{ attendance.client.social_name }}
                                </p>
                                <p class="mb-2">
                                    <b>Criado por:</b>
                                    <a target="blank" href="#">{{ attendance.creator.name }}</a>
                                </p>
                                <p class="mb-2">
                                    <strong>Data início atendimento:</strong>
                                    {{ moment(attendance.date_hour_start).format('DD/MM/YYYY HH:mm') }}
                                </p>
                                <div :class="{'modal':true,'inmodal':true, 'fade':true}"
                                     id="modalFinalizar"
                                     tabindex="-1"
                                     role="dialog" aria-hidden="true">
                                    <div class="modal-dialog modal-md">
                                        <div class="modal-content">
                                            <div class="modal-header">Finalizar atendimento</div>
                                            <div class="modal-body">
                                                <label>Data do término</label>
                                                <input type="datetime-local" v-model="date_end" class="form-control"/>
                                                <label>Quantidade de Horas</label>
                                                <input type="number" step="any" v-model="hours" class="form-control"/>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-white" data-dismiss="modal">
                                                    Cancelar
                                                </button>
                                                <button @click="endAttendimento" type="button" class="btn btn-primary">
                                                    Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ibox-content">
                                <a :href="'/panel/attendances/'+attendance.id+'/edit'"
                                   class="btn btn-primary" data-toggle="tooltip"
                                   data-placement="bottom" title="Atualizar dados atendimento">
                                    <i class="fa fa-edit"></i>
                                    Editar atendimento
                                </a>
<!--                                <a :href="'/panel/attendances/'+attendance.id+'/details-history'"
                                   class="btn btn-primary" data-toggle="tooltip"
                                   data-placement="bottom" title="Histórico detalhado">
                                    <i class="fa fa-file-text"></i>
                                    Histórico detalhado
                                </a>-->
                                <a v-if="$store.getters.attendance.is_open"
                                   class="btn btn-warning" href="#" :data-toggle="is_mobile ? '' :'modal'"
                                   :data-target="is_mobile ? '' :'#modalFinalizar'"
                                   @click="is_mobile ? endAttendimento() : null"
                                   data-placement="bottom" title="Finalizar atendimento">
                                    <i class="fa fa-check"></i>
                                    Finalizar atendimento
                                </a>
                                <a v-else
                                   class="btn btn-default" target="_blank"
                                   :href="'/panel/attendances/'+attendance.id+'/export_pdf'"
                                   data-placement="bottom" title="">
                                    <i class="fa fa-file-pdf-o"></i>
                                    Relatório do Atendimento
                                </a>

                            </div>
                        </div>
                        <div id="servicos" :class="{'tab-pane':true, 'active':tabs.servicos}">
                            <service-component></service-component>
                        </div>
                        <div id="pendencias" :class="{'tab-pane':true, 'active':tabs.pendencias}">
                            <pending-component></pending-component>
                        </div>
                        <div id="mensagens" :class="{'tab-pane':true, 'active':tabs.mensagens}">
                            <message-component></message-component>
                        </div>
                        <div id="arquivos" :class="{'tab-pane':true, 'active':tabs.arquivos}">
                            <archive-component></archive-component>
                        </div>
                        <div id="custos" :class="{'tab-pane':true, 'active':tabs.custos}">
                            <div class="alert alert-info alert-dismissible">
                                Essa área de custos só está disponível para <strong>administradores do sistema</strong>
                                <button aria-hidden="true" data-dismiss="alert" class="close" type="button">×
                                </button>
                            </div>
                            <cost-component></cost-component>
                        </div>
                        <div id="historico" :class="{'tab-pane':true, 'active':tabs.historico}">
                            <attendance-history-component></attendance-history-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <main class="main">
                <div class="topbar topbar-menu">
                    <div class="container-fluid">
                        <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#menu" type="button">
                        <span>
                            <i class="fa fa-check-circle text-success"></i>
                            {{ prefix }}
                        </span>
                            <i class="fa fa-angle-down"></i>
                        </button>
                    </div>
                    <div class="menu-collapse collapse" id="menu">
                        <ul class="list-group list-group-flush list-group-menu">
                            <li class="list-group-item">
                                <a data-toggle="collapse" data-target="#menu"
                                   @click="tabAction('sobre', 'Sobre')" href="#sobre">
                                    <i class="fa fa-info-circle"></i> Sobre</a>
                            </li>
                            <li class="list-group-item list-group-item-collapse">
                                <a data-toggle="collapse" data-target="#m-servicos" href="#">
                                <span>
                                    <i class="fa fa-cog"></i>
                                    Serviços
                                </span>
                                    <i class="fa fa-angle-down"></i>
                                </a>
                                <div class="submenu-collapse collapse" id="m-servicos">
                                    <ul class="list-group list-group-flush list-group-submenu">
                                        <li class="list-group-item">
                                            <a data-toggle="collapse" data-target="#menu"
                                               @click="tabAction('servicos','Serviço - Aferição de bomba', 'service1')"
                                               href="#servicos">-- Aferição de bomba</a>
                                        </li>
                                        <li class="list-group-item">
                                            <a data-toggle="collapse" data-target="#menu"
                                               @click="tabAction('servicos','Serviço - Auditoria de estoque de combustível', 'service2')"
                                               href="#servicos">-- Auditoria de estoque de combustível</a>
                                        </li>
                                        <li class="list-group-item">
                                            <a data-toggle="collapse" data-target="#menu"
                                               @click="tabAction('servicos','Serviço - Análise de qualidade', 'service3')"
                                               href="#servicos">-- Análise de qualidade</a>
                                        </li>
                                        <li class="list-group-item">
                                            <a data-toggle="collapse" data-target="#menu"
                                               @click="tabAction('servicos','Serviço - Limpeza de tanque de armazenamento', 'service4')"
                                               href="#servicos">-- Limpeza de tanque de armazenamento</a>
                                        </li>
                                        <li class="list-group-item">
                                            <a data-toggle="collapse" data-target="#menu"
                                               @click="tabAction('servicos','Serviço - Diálises', 'service5')" href="#servicos">--
                                                Diálises</a>
                                        </li>
                                        <li class="list-group-item">
                                            <a data-toggle="collapse" data-target="#menu"
                                               @click="tabAction('servicos','Serviço - Diversos', 'service6')" href="#servicos">--
                                                Diversos</a>
                                        </li>
                                        <li class="list-group-item">
                                            <a data-toggle="collapse" data-target="#menu"
                                               @click="tabAction('servicos','Serviço - Checklist operacional', 'service7')"
                                               href="#servicos">-- Checklist operacional</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="list-group-item">
                                <a data-toggle="collapse" data-target="#menu"
                                   @click="tabAction('pendencias','Pendências')" href="#pendencias">
                                    <i class="fa fa-check-double"></i> Pendências</a>
                            </li>
                            <li class="list-group-item">
                                <a data-toggle="collapse" data-target="#menu"
                                   @click="tabAction('mensagens','Mensagens')" href="#mensagens">
                                    <i class="fa fa-comment"></i> Mensagens</a>
                            </li>
                            <li class="list-group-item">
                                <a data-toggle="collapse" data-target="#menu"
                                   @click="tabAction('arquivos','Arquivos')" href="#arquivos">
                                    <i class="fa fa-archive"></i> Arquivos</a>
                            </li>
                            <li class="list-group-item">
                                <a data-toggle="collapse" data-target="#menu"
                                   @click="tabAction('custos','Custos')" href="#custos"><i
                                    class="fa fa-dollar-sign"></i> Custos</a>
                            </li>
                            <li class="list-group-item">
                                <a data-toggle="collapse" data-target="#menu"
                                   @click="tabAction('historico','Histórico')" href="#historico"><i
                                    class="fa fa-hourglass-half"></i> Histórico</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="tabs.sobre" class="list-group list-group-card">
                    <div v-if="$store.getters['attendance']" class="container-fluid">
                        <div class="card card-custom-table mb-2">
                            <div class="card-header">
                                <h6>Atendimento #{{ $store.getters.attendance.id }}</h6>
                                <span v-if="!$store.getters.attendance.is_open" class="badge badge-danger"
                                      style="float: right">Atendimento Finalizado</span>
                            </div>
                            <div class="card-body">
                                Cliente: <strong>{{ $store.getters.attendance.client.social_name }}</strong> <br>
                                Usuário: <strong>{{ $store.getters.attendance.creator.name }}</strong> <br>
                                Início: <strong> {{
                                    moment($store.getters.attendance.date_hour_start).format('DD/MM/YYYY HH:mm')
                                }}</strong> <br>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="list-group list-group-card">
                    <service-mobile-component v-if="tabs.servicos"  :service="servico" />
                    <pending-component v-if="tabs.pendencias"></pending-component>
                    <message-component v-if="tabs.mensagens"></message-component>
                    <archive-component v-if="tabs.arquivos"></archive-component>
                    <cost-component v-if="tabs.custos"></cost-component>
                    <attendance-history-component v-if="tabs.historico"></attendance-history-component>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import {URL_BASE} from "../models/helper";
import AttendanceHistoryModel from "../models/AttendanceHistoryModel";

export default {

    name: "AttendanceComponent",
    components: {},
    props: {
        attendance: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        laboratory_test: {
            type: Object,
            required: true
        },
        visual_aspect: {
            type: Object,
            required: true
        },
        types: {
            type: Object,
            required: true
        },
        pending_status: {
            type: Object,
            required: true
        },
        tab_active: {
            type: String,
        },
        url: {
            type: String
        },
        is_mobile: {
            type: Boolean
        }
    },
    data() {
        return {
            date_end: null,
            hours: 0,
            tabs: {
                sobre: false,
                servicos: false,
                pendencias: false,
                mensagens: false,
                arquivos: false,
                custos: false,
                historico: false,
            },
            servico: null,
            prefix: 'Selecionar'
        }
    },
    beforeCreate() {
    },
    created() {
        this.$store.commit('attendance', this.attendance)
        this.$store.commit('laboratory_test', this.laboratory_test)
        this.$store.commit('visual_aspect', this.visual_aspect)
        this.$store.commit('types', this.types)
        this.$store.commit('pending_status', this.pending_status)
        this.$store.commit('user', this.user)
        this.$store.commit('url', this.url)
        this.$store.commit('is_mobile', this.is_mobile)

        this.date_end = moment(this.is_mobile ? new Date() : this.date_end).format("YYYY-MM-DDTHH:mm");
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let tab = urlParams.get('tab')
        if (!tab) {
            if (this.tab_active != undefined && this.tab_active != '') {
                this.tabAction(this.tab_active);
            } else {
                this.tabAction('sobre');
                this.prefix = 'Sobre'
            }
        }else{
            this.tabAction(tab)
        }

    },
    methods: {
        moment: moment,
        endAttendimento() {
            this.errors = {};
            block()

            axios.put(URL_BASE + '/attendances', {
                id: this.attendance.id,
                client_id: this.attendance.client.id,
                date_hour_end: moment(new Date()).format("YYYY-MM-DD HH:mm")

            })
                .then((response) => {
                    showMessage('s', 'Finalizado com sucesso com sucesso');
                    let attendance = response.data.data
                    this.$store.commit('attendance', attendance)
                    $('#modalFinalizar').modal('hide');

                })
                .catch(error => {
                    $('#modalFinalizar').modal('hide');


                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(() => unBlock())
        },
        async loadHistory() {
            this.$store.commit("histories", await AttendanceHistoryModel.list());
        },
        tabAction(tab, prefix = '', subservico = null) {
            this.tabClear()
            this.tabs[tab] = true
            if (subservico) {
                this.servico = subservico
            }
            this.prefix = prefix
            switch (tab) {
                case 'historico':
                    this.loadHistory()
                    break;

            }
        },
        tabClear() {
            this.tabs = {
                sobre: false,
                servicos: false,
                pendencias: false,
                mensagens: false,
                arquivos: false,
                custos: false,
                historico: false,
            }

        },
        mounted() {
            this.loadHistory()
        }
    }
}

</script>
<style scoped>

</style>
