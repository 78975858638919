<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="{'ibox':true, 'float-e-margins':true, 'p-3':$store.getters.isMobile}">
                <div class="ibox-title">
                    <h5 :class="{'text-white':$store.getters.isMobile}">Cadastro de Auditoria de estoque de combustível</h5>
                </div>
                <div class="ibox-content">


                    <form :method="method" class="form-horizontal" name="frm_audits" id="frm_save" autocomplete="off"
                          @submit.prevent="submit">

                        <!-- inicio dos campos -->

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="fuel_id">Produto *</label>
                                <v-select @search="tanksSearch"
                                          :options="itemOptions"
                                          id="fuel_id"
                                          :reduce="option => option.id"
                                          label="description"
                                          placeholder="Escolha um produto"
                                          v-model="fields.fuel_id">
                                    <template slot="no-options">
                                        Pesquise por produto
                                    </template>
                                </v-select>


                                <div v-if="errors && errors.fuel_id" class="text-danger">{{ errors.fuel_id[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label>Data de início *</label>
                                <input type="date" name="start_date" class="form-control"
                                       v-model="fields.start_date">
                                <div v-if="errors && errors.start_date" class="text-danger">{{
                                    errors.start_date[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label>Data de término * </label>
                                <input type="date" name="end_date" class="form-control"
                                       v-model="fields.end_date">
                                <div v-if="errors && errors.end_date" class="text-danger">{{
                                    errors.end_date[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Encerrante Inicial </label>
                                <input  step=any  type="number" name="initial_terminant"
                                       v-model="fields.initial_terminant" class="form-control">
                                <div v-if="errors && errors.initial_terminant" class="text-danger">{{
                                    errors.initial_terminant[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Encerrante Final  </label>
                                <input  step=any  type="number" name="end_terminant"
                                       class="form-control"
                                       v-model="fields.end_terminant">
                                <div v-if="errors && errors.end_terminant" class="text-danger">{{
                                    errors.end_terminant[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Saldo Encerrante</label>
                                <input  step=any  type="number" name="balance_terminant"
                                       class="form-control"
                                       v-model="fields.balance_terminant = (parseFloat(fields.end_terminant) - fields.initial_terminant).toFixed(2)">
                                <div v-if="errors && errors.end_terminant" class="text-danger">{{
                                    errors.balance_terminant[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Estoque físico inicial </label>
                                <input  step=any  type="number" name="initial_physical_stock" v-model="fields.initial_physical_stock"
                                       class="form-control">
                                <div v-if="errors && errors.initial_physical_stock" class="text-danger">{{
                                    errors.initial_physical_stock[0]
                                    }}
                                </div>
                            </div>


                            <div class="form-group col-md-12">
                                <label>Entradas no período </label>
                                <input  step=any  type="number" name="system_input" class="form-control"
                                       v-model="fields.system_input">
                                <div v-if="errors && errors.system_input" class="text-danger">{{
                                    errors.system_input[0]
                                    }}
                                </div>
                            </div>



                            <div class="form-group col-md-12">
                                <label>Saída no período </label>
                                <input  step=any  type="number" name="system_output" class="form-control"
                                       v-model="fields.system_output">
                                <div v-if="errors && errors.system_output" class="text-danger">{{
                                    errors.system_output[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Saldo Calculado</label>
                                <input readonly type="number" name="calculated_balance" class="form-control"
                                       v-model="fields.calculated_balance = parseFloat(fields.initial_physical_stock) + parseFloat(fields.system_input) - fields.system_output">
                                <div v-if="errors && errors.calculated_balance" class="text-danger">{{
                                    errors.calculated_balance[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Estoque físico atual *</label>
                                <input   step=any  type="number" name="current_physical_stock" v-model="fields.current_physical_stock"
                                       class="form-control">
                                <div v-if="errors && errors.current_physical_stock" class="text-danger">{{
                                    errors.current_physical_stock[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Resultado da auditoria</label>
                                <input  readonly type="number" name="audit_result_balance" class="form-control"
                                       v-model="fields.audit_result_balance = (parseFloat(fields.current_physical_stock) - fields.calculated_balance).toFixed(2)">
                                <div v-if="errors && errors.audit_result_balance" class="text-danger">{{
                                    errors.audit_result_balance[0]
                                    }}
                                </div>
                            </div>




                            <mfile-upload :images="fields.images" :folder="'service_fuel_stock_audits'" @deletedImage="removeImage"></mfile-upload>


                        </div>


                        <button class="btn btn-primary" id="bt_salvar" type="submit">
                            <i class="fa fa-save"></i>
                            Salvar <span v-if="item">Alterações</span>
                        </button>


<!--                        <button @click="submit(true)" class="btn btn-default" id="bt_salvar_adicionar" type="submit">-->
<!--                            <i class="fa fa-save"></i>-->
<!--                            Salvar e adicionar novo-->
<!--                        </button>-->

                        <a @click="$emit('isForm', false)" :class="{'btn':true, 'btn-default':true, 'text-white':$store.getters.isMobile}" id="ln_listar_form">
                            <i class="fa fa-list-ul"></i>
                            Listar
                        </a>

                        <!-- FIM -->
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select';
    import moment from 'moment'
    import {saveOffline, URL_BASE} from "../../../models/helper";
    import FuelPumpModel from "../../../models/FuelPumpModel";
    import ServiceFuelStockAuditModel from "../../../models/ServiceFuelStockAuditModel";
    import FuelsModel from "../../../models/FuelsModel";

    export default {
        name: "ServiceFuelStockAuditsFormComponent",
        components: {vSelect},
        props: {
            item: {
                type: Object,
                required: false
            }
        },
        data() {
            return {

                loaded: true,
                errors: {},
                itemOptions: [],
                fields: new ServiceFuelStockAuditModel(),
                method: "post"
            }
        },
        cerated() {
            if (this.item) {
                this.fields = {}
            }
        },
        methods: {
            moment: moment,
            async tanksSearch(search, loading) {
                loading(true)
                this.itemOptions = await FuelsModel.find(search);
                loading(false)
            },
            async submit(newRecord = false) {

                //this.method = this.item ? 'put': 'post';

                let form = document.forms.namedItem('frm_audits');
                let Data = new FormData(form);
                Data.append("fuel_id", this.fields.fuel_id)
                Data.append("attendance_id", this.$store.getters.attendance.id);

                let url = ServiceFuelStockAuditModel._module();

                if (this.item) {
                    this.method = 'put';
                    Data.append("id", this.item.id)
                    Data.append("_method", this.method)
                }

                return this.save(url, Data, newRecord);
            },
            async save(url, data, newRecord = false) {

                if (!navigator.onLine) {
                    await saveOffline('fuel_stock_audit', data);
                    this.$emit('isForm', false)
                    return;
                }
                this.errors = {};
                block()

                axios.post(url, data)
                    .then(() => {
                        showMessage('s', 'Salvo com sucesso');
                        this.$emit('isForm', false)


                    })
                    .catch(error => {

                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }


                        if (error.response.status === 401) {
                            showMessage('w', error.response.data.server_error);
                        }

                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors || {};
                            showMessage('w', 'Preencha todos os campos obrigatórios.');
                        }
                    })
                    .finally(() => unBlock())
            },
            removeImage(index) {
                this.fields.images.splice(index, 1);
            }

        },
        mounted() {
            if (this.item) {
                this.fields = this.item;
                this.fields.start_date =  moment(this.fields.start_date).format("YYYY-MM-DD")
                this.fields.end_date =  moment(this.fields.end_date).format("YYYY-MM-DD")
                this.itemOptions.push(
                    {
                        id: this.item.fuel.id,
                        description: this.item.fuel.description
                    }
                )
                this.fields.fuel_id = this.item.fuel.id
            }
            // console.log(this.$store.getters.attendance)

        }
    }

</script>

<style scoped>

</style>
