import { render, staticRenderFns } from "./ArchiveComponent.vue?vue&type=template&id=d314b19a&scoped=true&"
import script from "./ArchiveComponent.vue?vue&type=script&lang=js&"
export * from "./ArchiveComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d314b19a",
  null
  
)

export default component.exports