<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="{'ibox':true, 'float-e-margins':true, 'p-3':$store.getters.isMobile}">
                <div class="ibox-title">
                    <h5 :class="{'text-white':$store.getters.isMobile}">Cadastro de Aferição de Bombas</h5>
                </div>
                <div class="ibox-content">


                    <form :method="method" class="form-horizontal" name="frm_pump" id="frm_save" autocomplete="off"
                          @submit.prevent="submit">

                        <!-- inicio dos campos -->

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="fuel_pump_id">Bomba</label>
                                <v-select @search="tanksSearch"
                                          :options="itemOptions"
                                          id="fuel_pump_id"
                                          :reduce="option => option.id"
                                          label="description"
                                          placeholder="Escolha um"
                                          v-model="fields.fuel_pump_id">
                                    <template slot="no-options">
                                        Pesquise por bombas
                                    </template>
                                </v-select>

                                <div v-if="errors && errors.fuel_pump_id" class="text-danger">{{
                                        errors.fuel_pump_id[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="terminant">Encerrante</label>
                                <input type="text" name="terminant" id="terminant" v-model="fields.terminant"
                                       class="form-control">
                                <div v-if="errors && errors.terminant" class="text-danger">{{
                                        errors.terminant[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="initial_result">Resultado Inicial</label>
                                <input type="text" name="initial_result" id="initial_result"
                                       v-model="fields.initial_result" class="form-control">
                                <div v-if="errors && errors.initial_result" class="text-danger">{{
                                        errors.initial_result[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">

                                <label>
                                    <input type="checkbox" class="i-checks" name="is_regulated" id="is_regulated"
                                           v-model="fields.is_regulated" value="1">
                                    Foi Regulada?
                                </label>
                                <div v-if="errors && errors.is_regulated" class="text-danger">{{
                                        errors.is_regulated[0]
                                    }}
                                </div>
                            </div>

                            <div v-if="fields.is_regulated" class="form-group col-md-12">
                                <label for="result_after_adjustment">Resultado após o ajuste</label>
                                <input type="text" name="result_after_adjustment" id="result_after_adjustment"
                                       class="form-control"
                                       v-model="fields.result_after_adjustment">
                                <div v-if="errors && errors.result_after_adjustment" class="text-danger">{{
                                        errors.result_after_adjustment[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <input type="checkbox" name="sealed" id="sealed" v-model="fields.sealed"
                                       value="1">
                                <label for="sealed">Foi lacrado nesse atendimento?</label>

                                <div v-if="errors && errors.sealed" class="text-danger">{{
                                        errors.sealed[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="seal_number">Numero do lacre</label>
                                <input type="text" name="seal_number" id="seal_number" class="form-control"
                                       v-model="fields.seal_number">
                                <div v-if="errors && errors.seal_number" class="text-danger">{{
                                        errors.seal_number[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12 ">
                                <input type="checkbox" name="measurer_changed" id="measurer_changed" value="1"
                                       v-model="fields.measurer_changed">
                                <label for="measurer_changed">Medidor alterado ?</label>

                                <div v-if="errors && errors.measurer_changed" class="text-danger">{{
                                        errors.measurer_changed[0]
                                    }}
                                </div>
                            </div>

                            <mfile-upload :images="fields.images" :folder="'service_pump_standardisations'"
                                          @deletedImage="removeImage"></mfile-upload>

                        </div>

                        <button class="btn btn-primary" id="bt_salvar" type="submit">
                            <i class="fa fa-save"></i>
                            Salvar
                            <span v-if="item">Alterações</span>
                        </button>


                        <a @click="$emit('isForm', false)"
                           :class="{'btn':true, 'btn-default':true, 'text-white':$store.getters.isMobile}"
                           id="ln_listar_form">
                            <i class="fa fa-list-ul"></i>
                            Listar
                        </a>

                        <!-- FIM -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import moment from 'moment'
import ServicePumpStandardisationModel from "../../../models/ServicePumpStandardisationModel";
import {URL_BASE, saveOffline} from "../../../models/helper";
import FuelPumpModel from "../../../models/FuelPumpModel";


export default {
    name: "ServicePumpStandardisationListComponent",
    components: {vSelect},
    props: {
        item: {
            type: Object,
            required: false
        }
    },
    data() {
        return {

            loaded: true,
            errors: {},
            itemOptions: [],
            fields: new ServicePumpStandardisationModel(),
            method: "post",
            files: []
        }
    },
    cerated() {
        if (this.item) {
            this.fields = {}
        }
    },
    methods: {
        moment: moment,
        async tanksSearch(search, loading) {
            loading(true)
            this.itemOptions = await FuelPumpModel.find(search);
            loading(false)
        },
        submit() {

            let form = document.forms.namedItem('frm_pump');
            let Data = new FormData(form);
            Data.append("fuel_pump_id", this.fields.fuel_pump_id)
            Data.append("attendance_id", this.$store.getters.attendance.id);
            Data.append("is_regulated", this.fields.is_regulated ? 1 : 0)
            Data.append("sealed", this.fields.sealed ? 1 : 0)
            Data.append("measurer_changed", this.fields.measurer_changed ? 1 : 0)

            let url = ServicePumpStandardisationModel._module();

            if (this.item) {
                this.method = 'put';
                Data.append("id", this.item.id)
                Data.append("_method", this.method)
            }

            return this.save(url, Data);
        },
        async save(url, data) {

            if (!navigator.onLine) {
                await saveOffline('pump_standardisation', data);
                //this.$emit('isForm', false)
                return;
            }

            this.errors = {};
            block()

            axios.post(url, data)
                .then(() => {
                    showMessage('s', 'Salvo com sucesso');
                    this.$emit('isForm', false)
                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(() => unBlock())
        },
        removeImage(index) {
            this.fields.images.splice(index, 1);
        }

    },
    mounted() {
        if (this.item) {
            this.fields = this.item;
            this.itemOptions.push(
                {
                    id: this.item.fuel_pump.id,
                    description: this.item.fuel_pump.description
                }
            )
            this.fields.fuel_pump_id = this.item.fuel_pump.id
        }
        // console.log(this.$store.getters.attendance)

    }
}

</script>
<style scoped>

</style>
