<template>
    <div v-if="!$store.getters.isMobile" class="ibox mb-0">
        <div class="ibox-title">
            <h4>Listagem de diálises</h4>
            <div class="ibox-tools" v-if="$store.getters.attendance.is_open">
                <a @click="$emit('isForm', true)" class="btn btn-primary ">
                    <i class="fa fa-plus"></i>
                    Cadastrar
                </a>
            </div>
        </div>
        <div class="ibox-content">
            <form method="get" v-on:submit.prevent>
                <div class="input-group">
                    <input type="text" v-on:keyup.enter="list" name="name"
                           class="form-control"
                           v-model="search.name"
                           placeholder="Localizar">
                    <div class="input-group-append">
                        <button @click="list" class="btn btn-block btn-primary">
                            <i class="fa fa-search"></i>
                            Pesquisar
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="ibox-content">
            <div class="table-responsive">
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th style="width: 100px; text-align: center">Ações</th>
                            <th>Terceirizado?</th>
                            <th>Automotor</th>
                            <th>Horímetro/odômetro</th>
                            <th>Comentário</th>
                            <th class="hidden-xs hidden-sm" style="width: 150px;">Criado em</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="dataset.length > 0" :id="'tr-sd-' + item.id" v-for="item in dataset">
                            <td style="text-align: center">
                                <div class="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button"
                                            class="btn btn-default dropdown-toggle"
                                            data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                        Ações
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                        <template v-if="$store.getters.attendance.is_open">
                                            <a class="dropdown-item"
                                               @click="$emit('isEditing', item)">Editar
                                            </a>
                                            <link-destroy-component
                                                :line-id="'tr-sd-' + item.id"
                                                :link=item.destroyURL()>
                                            </link-destroy-component>
                                        </template>
                                    </div>
                                </div>
                            </td>
                            <td align="center">
                                <template v-if="item.is_outsourced">
                                    <span class="badge badge-success">Sim</span>
                                    <br>
                                    <strong>{{item.outsource.name}}</strong>
                                </template>
                                <span v-else class="badge badge-danger">Não</span>
                            </td>
                            <td>{{ `${item.automotor.client_code || ''} - ${item.automotor.model || ''} ${item.automotor.board || ''}` }}</td>
                            <td>{{item.hour_meter}}</td>
                            <td>{{item.commentary}}</td>
                            <td class="hidden-xs hidden-sm">{{moment(item.created_at).format("DD/MM/YYYY HH:mm")}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <span v-else>
        <div class="topbar topbar-menu topbar-menu-action">
            <div class="container-fluid">
                <div class="menu-action">
                    <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#filter" type="button">
                            <span>
                                <i class="fa fa-filter"></i>
                                Filtrar pesquisa
                            </span>
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <a v-if="$store.getters.attendance.is_open"
                       @click="$emit('isForm', true)"
                       class="btn btn-success" href="#">Cadastrar</a>
                </div>
            </div>
            <div class="container-fluid">
                <div class="filter-collapse collapse" id="filter">
                     <form method="get" v-on:submit.prevent>
                        <div class="form-group">
                            <input type="text" v-on:keyup.enter="list" name="name"
                                   class="form-control"
                                   v-model="search.tank_description"
                                   placeholder="Pesquisar por descrição">
                        </div>
                            <button @click="list" class="btn btn-block btn-success">
                                    <i class="fa fa-search"></i>
                                    Pesquisar
                                </button>
                    </form>
                </div>
            </div>
        </div>
        <div class="list-group list-group-card">
            <div class="container-fluid">
                <div v-if="dataset.length > 0" :id="'tr-scst-' + item.id" v-for="(item, index) in dataset"
                     class="card card-custom-table card-custom-collapse">
                    <div class="card-content">
                        <div class="card-body-content">
                            <div class="card-header">
                                <h6>Terceirizado </h6>
                            </div>
                            <div class="card-body">
                              <template v-if="item.is_outsourced">
                                    <span class="badge badge-success">Sim</span>
                                    <br>
                                    <strong>{{item.outsource.name}}</strong>
                                </template>
                                <span v-else class="badge badge-danger">Não</span>
                            </div>
                        </div>
                        <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#item-5"
                                type="button">
                            <i class="fa fa-angle-down"></i>
                        </button>
                    </div>
                    <ul class="list-group list-group-table collapse" id="item-5">
                         <li class="list-group-item">
                            <strong>Automotor</strong>: {{ `${item.automotor.client_code || ''} - ${item.automotor.model || ''} ${item.automotor.board || ''}` }}
                        </li>
                         <li class="list-group-item">
                            <strong>Horímetro/odômetro</strong>: {{item.hour_meter}}
                        </li>
                        <li class="list-group-item">
                            <strong>Comentário</strong>: {{item.commentary}}
                        </li>
                        <li class="list-group-item">
                          Criado em:  {{moment(item.created_at).format("DD/MM/YYYY HH:mm")}}
                        </li>
                        <li class="list-group-item">
                            <a @click="$emit('isEditing', item)">
                                <i class="fa fa-pencil"></i>
                                Editar
                            </a>
                        </li>
                        <li class="list-group-item">
                            <link-destroy-component
                                :dropdown="false"
                                :danger="true"
                                :line-id="'tr-scst-' + item.id"
                                :link=item.destroyURL()>
                                                    </link-destroy-component>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
    import 'vue-select/dist/vue-select.css';
    import moment from 'moment'
    import {destroyURL} from "../../../models/helper";
    import ServiceCleaningStorageTankModel from "../../../models/ServiceCleaningStorageTankModel";
    import ServiceDialysisModel from "../../../models/ServiceDialysisModel";
    export default {
        name: "ServiceDialisiListComponent",
        props: {
            id: {
                type: String,
                required: false
            },
            isForm: {
                type: Boolean,
                required: true
            },
            fields: {
                type: Object,
                required: false
            }
        },
        data() {
            return {

                loaded: true,
                errors: {},
                method: 'POST',
                itemOptions: [],
                dataset: [],
                search: {}
            }
        },
        cerated() {
        },
        methods: {
            moment: moment,
            async list() {
                this.dataset = await ServiceDialysisModel.list('/', this.search);
            }

        },
        mounted() {
            this.list()
        }
    }
</script>
<style scoped>

</style>
