import {fromJson, searchOffline, URL_BASE} from "./helper";
import store from "../store";


class AutomotorModel {


    constructor() {

        this.id = null;
        this.client_id = null;
        this.type = null;
        this.name = null;
        this.board = null;
        this.year = null;
        this.description = null;
        this.status = null;

    }


    static _module() {
        return URL_BASE + "/automotors";
    }


    static fromJson(object) {
        return fromJson((new AutomotorModel()), object);
    }

    static async find(search, client_id) {
        let url = this._module() + "/find";
        var collection = new Array()

        if (!navigator.onLine){
            return await searchOffline('automotor', client_id ? client_id : store.getters.attendance.client.id);
        }

        await axios.get(url,
            {
                params: {
                    search: search,
                    client_id: client_id ? client_id : store.getters.attendance.client.id
                }
            }
        )
            .then((response) => {
                collection = response.data.map(function (model) {
                    return {
                        id: model.id,
                        description: `${model.client_code || ''} - ${model.model || ''} ${model.board || ''}`,
                    }
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

}

export default AutomotorModel;
