import {fromJson, URL_BASE} from "./helper";
import store from "../store";


class AttendanceHistoryModel {


    constructor() {

        this.id = null;
        this.attendance_id = null
        this.description = null
        this.user = {}
        this.created_at = null

    }


    static _module() {
        return URL_BASE + "/attendances";
    }

    static fromJson(object) {
        return fromJson((new AttendanceHistoryModel()), object);
    }

    destroyURL() {
        return AttendanceHistoryModel._module() + "/" + this.id + "/destroy"
    }


    static async list(endpoint = `/${store.getters.attendance.id}/histories`, data = {}) {
        let url = this._module() + endpoint;
        var collection = new Array()
        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return AttendanceHistoryModel.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }
}

export default AttendanceHistoryModel;
