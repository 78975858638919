import {fromJson, URL_BASE} from "./helper";


class ServiceImageModel {


    constructor() {

        this.id = null;
        this.url = null;
        this.description = null;
        this.field =  null;

    }


    static _module() {
        return URL_BASE + "/service_images";
    }

    static fromJson(object) {
        return fromJson((new ServiceImageModel()), object);
    }

    destroyURL() {
        return ServiceImageModel._module() + "/" + this.id + "/destroy"
    }

    static uploadURL() {
        return ServiceImageModel._module() + "/upload_base64"
    }
}

export default ServiceImageModel;
