<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="{'ibox':true, 'float-e-margins':true, 'p-3':$store.getters.isMobile}">
                <div class="ibox-title">
                    <h5 :class="{'text-white':$store.getters.isMobile}">Cadastro de serviço diverso</h5>
                </div>
                <div class="ibox-content">


                    <form :method="method" class="form-horizontal" name="frm_cleaning"
                          autocomplete="off"
                          @submit.prevent="submit">

                        <!-- inicio dos campos -->

                        <div class="form-row">

                            <div class="form-group col-md-12">
                                <label>Comentário</label>
                                <textarea class="form-control" name="commentary" v-model="fields.commentary"
                                          rows="4"></textarea>
                                <div v-if="errors && errors.commentary" class="text-danger">{{
                                    errors.commentary[0]
                                    }}
                                </div>
                            </div>
                            <mfile-upload :images="fields.images" :folder="'service_dialysis'" @deletedImage="removeImage"></mfile-upload>


                        </div>


                        <button class="btn btn-primary" id="bt_salvar" type="submit">
                            <i class="fa fa-save"></i>
                            Salvar <span v-if="item">Alterações</span>
                        </button>


                        <!--                        <button @click="submit(true)" class="btn btn-default" id="bt_salvar_adicionar" type="submit">-->
                        <!--                            <i class="fa fa-save"></i>-->
                        <!--                            Salvar e adicionar novo-->
                        <!--                        </button>-->

                        <a @click="$emit('isForm', false)" :class="{'btn':true, 'btn-default':true, 'text-white':$store.getters.isMobile}" id="ln_listar_form">
                            <i class="fa fa-list-ul"></i>
                            Listar
                        </a>

                        <!-- FIM -->
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select';
    import moment from 'moment'
    import {saveOffline, URL_BASE} from "../../../models/helper";
    import AutomotorModel from "../../../models/AutomotorModel";
    import ServiceDialysisModel from "../../../models/ServiceDialysisModel";
    import ClientModel from "../../../models/ClientModel";
    import ServiceDiverseModel from "../../../models/ServiceDiverseModel";

    export default {
        name: "ServiceDiverseormComponent",
        components: {vSelect},
        props: {
            item: {
                type: Object,
                required: false
            }
        },
        data() {
            return {

                loaded: true,
                errors: {},
                itemOptions: [],
                outSourceOptions: [],
                fields: new ServiceDiverseModel(),
                method: "post",
            }
        },
        created() {
            if (this.item) {
                this.fields = {}
            }
        },
        methods: {
            moment: moment,
            async submit(newRecord = false) {

                //this.method = this.item ? 'put': 'post';

                let form = document.forms.namedItem('frm_cleaning');
                let Data = new FormData(form);


                Data.append("attendance_id", this.$store.getters.attendance.id);
                let url = ServiceDiverseModel._module();

                if (this.item) {
                    this.method = 'put';
                    Data.append("id", this.item.id)
                    Data.append("_method", this.method)
                }

                return this.save(url, Data, newRecord);
            },
            async save(url, data, newRecord = false) {
                if (!navigator.onLine) {
                    await saveOffline('diverses', data);
                    this.$emit('isForm', false)
                    return;
                }
                this.errors = {};
                block()

                axios.post(url, data)
                    .then(() => {
                        showMessage('s', 'Salvo com sucesso');
                        this.$emit('isForm', false)


                    })
                    .catch(error => {

                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }


                        if (error.response.status === 401) {
                            showMessage('w', error.response.data.server_error);
                        }

                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors || {};
                            showMessage('w', 'Preencha todos os campos obrigatórios.');
                        }
                    })
                    .finally(() => unBlock())
            },

            removeImage(index) {
                this.fields.images.splice(index, 1);
            }

        },
        mounted() {
            if (this.item) {
                this.fields = this.item;
                // this.itemOptions.push(
                //     {
                //         id: this.item.automotor.id,
                //         description: this.item.automotor.name
                //     }
                // )

            }


        }
    }

</script>

<style scoped>

</style>
