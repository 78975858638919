<template>
    <div v-if="!$store.getters.isMobile" class="card p-3">
        <div class="ibox mb-0">
            <div class="ibox-title">
                <span class="mr-2"><strong>Última mensagem:</strong></span>
                <span v-if="dataset.length > 0">{{ moment(dataset[0].created_at).format("DD/MM/YYYY HH:mm") }}</span>
                <span>Mensagens do atendimento</span>
            </div>
            <div class="ibox-content">
                <div class="chat-discussion">
                    <message-item-component v-for="(item, index) in dataset" :key="index"
                                            :item="item"></message-item-component>
                </div>
            </div>
            <div class="ibox-content">
                <div class="chat-message-form">
                    <div class="form-group" v-if="$store.getters.attendance.is_open">
                        <textarea v-on:keyup.enter="send"
                                  class="form-control message-input"
                                  name="message" v-model="message"
                                  placeholder="Digite o texto da mensagem">
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="p-1">
        <div class="messages">
            <div v-for="(item, index) in dataset" :key="index"
                 :class="{'container':true, 'darker':$store.getters.isLogado(item.user.id)}">

                <template v-if="$store.getters.isLogado(item.user.id)">
                    <span class="message-avatar fa fa-user-circle fa-4x right"></span>
                    <strong><a class="message-author" href="#"> {{ item.user.name }} </a></strong>
                    <p>{{ item.message }}</p>
                    <small class="time-left">{{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}</small>
                </template>
                <template v-else>
                    <span class="message-avatar fa fa-user-circle fa-4x"></span>
                    <strong><a class="message-author" href="#"> {{ item.user.name }} </a></strong>
                    <p>{{ item.message }}</p>
                    <small class="time-right">{{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}</small>
                </template>

            </div>
        </div>
        <div class="actions" v-if="$store.getters.attendance.is_open">
            <div class="input-group mb-12">
                <input v-model="message" v-on:keyup.enter="send" type="text" class="form-control" placeholder="Digite sua mensagem"
                       aria-label=""
                       aria-describedby="basic-addon2">
                <div class="input-group-append">
                    <button @click="send" class="btn btn-outline-secondary" type="button">
                        <span class="fa fa-check-double"></span>
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import MessageModel from "../../models/MessageModel";
import moment from 'moment'

export default {

    name: "MessageComponent",
    data() {
        return {
            message: '',
            dataset: []
        }
    },
    methods: {
        send() {
            this.errors = {};
            block()
            let url = MessageModel._module();
            axios.post(url, {
                attendance_id: this.$store.getters.attendance.id,
                message: this.message
            })
                .then(() => {
                    this.list()
                    this.message = ''
                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }

                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Sua mensagem não está em conformidade');
                    }
                })
                .finally(() => unBlock())
        },
        async list() {
            this.dataset = await MessageModel.list('/');
        },
        moment: moment,
    },
    mounted() {
        this.list()
    }
}
</script>
<style scoped>
@media only screen and (max-width: 600px) {

    .messages {
        overflow-y: scroll;
    }

    .actions {
        position: fixed;
        bottom: 0;
        background: #343a40;
        padding: 10px;
        width: 100%;
    }

    .container {
        border: 2px solid #dedede;
        background-color: #f1f1f1;
        border-radius: 5px;
        padding: 10px;
        margin: 10px 0;
    }


    /* Darker chat container */
    .darker {
        border-color: #ccc;
        background-color: #ddd;
    }

    /* Clear floats */
    .container::after {
        content: "";
        clear: both;
        display: table;
    }

    /* Style images */
    .container span {
        float: left;
        max-width: 60px;
        width: 100%;
        margin-right: 20px;
        border-radius: 50%;
    }

    /* Style the right image */
    .container span.right {
        float: right;
        margin-left: 20px;
        margin-right: 0;
    }

    /* Style time text */
    .time-right {
        float: right;
        color: #aaa;
    }

    /* Style time text */
    .time-left {
        float: left;
        color: #999;
    }
}
</style>
