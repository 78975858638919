<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="{'ibox':true, 'float-e-margins':true, 'p-3':$store.getters.isMobile}">
                <div class="ibox-title">
                    <h5 :class="{'text-white':$store.getters.isMobile}">Cadastro de limpeza de tanque de armazenamento</h5>
                </div>
                <div class="ibox-content">


                    <form :method="method" class="form-horizontal" name="frm_cleaning"
                          autocomplete="off"
                           @submit.prevent="submit">

                        <!-- inicio dos campos -->

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label>Tanque *</label>
                                <v-select @search="tanksSearch"
                                          :options="itemOptions"
                                          :reduce="option => option.id"
                                          label="description"
                                          placeholder="Escolha um tanque"
                                          v-model="fields.tank_id">
                                    <template slot="no-options">
                                        Pesquise por tanques
                                    </template>
                                </v-select>


                                <div v-if="errors && errors.tank_id" class="text-danger">{{ errors.tank_id[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Volume inicial *</label>
                                <money class="form-control" name="initial_volume" v-model.lazy="fields.initial_volume"
                                       v-bind="money" value=""></money>
                                <div v-if="errors && errors.initial_volume" class="text-danger">{{
                                    errors.initial_volume[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Volume final</label>
                                <money class="form-control" name="final_volume" v-model="fields.final_volume"
                                       v-bind="money"></money>
                                <div v-if="errors && errors.final_volume" class="text-danger">{{
                                    errors.final_volume[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Perda * </label>
                                <input readonly type="number" step="any" class="form-control" name="loss"
                                       v-model="fields.loss"></input>
                                <div v-if="errors && errors.loss" class="text-danger">{{
                                    errors.loss[0]
                                    }}
                                </div>
                            </div>
                            <div class="form-group col-md-12">
                                <label>Volume de borra aproximado * </label>
                                <money type="text" name="approximate_sludge_volume"
                                       class="form-control"
                                       v-model="fields.approximate_sludge_volume" v-bind="money"/>
                                <div v-if="errors && errors.approximate_sludge_volume" class="text-danger">{{
                                    errors.approximate_sludge_volume[0]
                                    }}
                                </div>
                            </div>

                            <mfile-upload :images="fields.images" :folder="'service_cleaning_storage_tanks'" @deletedImage="removeImage"></mfile-upload>


                        </div>


                        <button class="btn btn-primary" id="bt_salvar" type="submit">
                            <i class="fa fa-save"></i>
                            Salvar <span v-if="item">Alterações</span>
                        </button>


                        <!--                        <button @click="submit(true)" class="btn btn-default" id="bt_salvar_adicionar" type="submit">-->
                        <!--                            <i class="fa fa-save"></i>-->
                        <!--                            Salvar e adicionar novo-->
                        <!--                        </button>-->

                        <a @click="$emit('isForm', false)"  :class="{'btn':true, 'btn-default':true, 'text-white':$store.getters.isMobile}" id="ln_listar_form">
                            <i class="fa fa-list-ul"></i>
                            Listar
                        </a>

                        <!-- FIM -->
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select';
    import moment from 'moment'
    import {saveOffline, URL_BASE} from "../../../models/helper";
    import TankModel from "../../../models/TankModel";
    import ServiceCleaningStorageTankModel from "../../../models/ServiceCleaningStorageTankModel";

    export default {
        name: "ServiceCleaningStorageTankFormComponent",
        components: {vSelect},
        props: {
            item: {
                type: Object,
                required: false
            }
        },
        data() {
            return {

                loaded: true,
                errors: {},
                itemOptions: [],
                fields: new ServiceCleaningStorageTankModel(),
                method: "post",
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false
                }
            }
        },
        watch: {
            'fields.final_volume': function (val) {
                this.setLoss(val - this.fields.initial_volume)
            },
            'fields.initial_volume': function (val) {
                this.setLoss(this.fields.final_volume - val);
            },


        },
        created() {
            if (this.item) {
                this.fields = {}
            }
        },
        methods: {
            moment: moment,
            async tanksSearch(search, loading) {
                loading(true)
                this.itemOptions = await TankModel.find(search);
                loading(false)
            },
            async submit(newRecord = false) {

                //this.method = this.item ? 'put': 'post';

                let form = document.forms.namedItem('frm_cleaning');
                let Data = new FormData(form);

                Data.append("tank_id", this.fields.tank_id)
                Data.append("attendance_id", this.$store.getters.attendance.id);

                let url = ServiceCleaningStorageTankModel._module();

                if (this.item) {
                    this.method = 'put';
                    Data.append("id", this.item.id)
                    Data.append("_method", this.method)
                }

                return this.save(url, Data, newRecord);
            },
            async save(url, data, newRecord = false) {
                if (!navigator.onLine) {
                    await saveOffline('cleaning_storage_tank', data);
                    this.$emit('isForm', false)
                    return;
                }
                this.errors = {};
                block()

                axios.post(url, data)
                    .then(() => {
                        showMessage('s', 'Salvo com sucesso');
                        this.$emit('isForm', false)


                    })
                    .catch(error => {

                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }


                        if (error.response.status === 401) {
                            showMessage('w', error.response.data.server_error);
                        }

                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors || {};
                            showMessage('w', 'Preencha todos os campos obrigatórios.');
                        }
                    })
                    .finally(() => unBlock())
            },
            async loadTanks() {
                this.itemOptions = await TankModel.find('');
            },
            removeImage(index) {
                this.fields.images.splice(index, 1);
            },
            setLoss(val) {
                this.fields.loss = val > 0 ? 0 : val;
            }

        },
        mounted() {
            this.loadTanks()
            if (this.item) {
                this.fields = this.item;
                this.itemOptions.push(
                    {
                        id: this.item.tank.id,
                        description: this.item.tank.description
                    }
                )
                this.fields.tank_id = this.item.tank.id
            }

        }
    }

</script>

<style scoped>

</style>
