/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import {URL_BASE} from "./models/helper";
import store from "./store/index"
import VueTheMask from 'vue-the-mask'
import money from 'v-money'

require('./bootstrap');


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize
 * the JavaScript scaffolding to fit your unique needs.
 */

window.Vue = require('vue');
Vue.use(VueTheMask)
Vue.use(money, {precision: 2})


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('link-destroy-component', require('./components/LinkDestroyComponent').default);
Vue.component('attendance-component', require('./components/AttendanceComponent').default);
Vue.component('attendance-list-component', require('./components/AttendanceListComponent').default);
Vue.component('attendance-form-component', require('./components/AttendanceFormComponent').default);
Vue.component('attendance-history-component', require('./components/AttendanceHistoryComponent').default);
Vue.component('service-component', require('./components/service/ServiceComponent').default);
Vue.component('service-mobile-component', require('./components/service/ServiceMobileComponent').default);

//service_pumps_standardisations
Vue.component('service-pump-standardisation-list-component', require('./components/service/service_pump_standardisations/ListComponent').default);
Vue.component('service-pump-standardisation-form-component', require('./components/service/service_pump_standardisations/FormComponent').default);

//service_fuel_stock_audits
Vue.component('service-fuel-stock-audits-list-component', require('./components/service/service_fuel_stock_audits/ListComponent').default);
Vue.component('service-fuel-stock-audits-form-component', require('./components/service/service_fuel_stock_audits/FormComponent').default);


//service_fuel_quality
Vue.component('service-fuel-quality-list-component', require('./components/service/service_fuel_quality/ListComponent.vue').default);
Vue.component('service-fuel-quality-form-component', require('./components/service/service_fuel_quality/FormComponent.vue').default);


//service_cleaning_storage_tank
Vue.component('service-cleaning-storage-tank-list-component', require('./components/service/service_cleaning_storage_tanks/ListComponent').default);
Vue.component('service-cleaning-storage-tank-form-component', require('./components/service/service_cleaning_storage_tanks/FormComponent').default);


//service_dialysis
Vue.component('service-dialysi-list-component', require('./components/service/service_dialysis/ListComponent').default);
Vue.component('service-dialysi-form-component', require('./components/service/service_dialysis/FormComponent').default);



//service_diverses
Vue.component('service-diverse-list-component', require('./components/service/service_diverses/ListComponent').default);
Vue.component('service-diverse-form-component', require('./components/service/service_diverses/FormComponent').default);

//Checklist
Vue.component('service-checklist-component', require('./components/service/service_checklists/ChecklistComponent').default);


//Pendings
Vue.component('pending-component', require('./components/pendings/PendingComponent').default);
Vue.component('pending-list-component', require('./components/pendings/ListComponent').default);
Vue.component('pending-form-component', require('./components/pendings/FormComponent').default);

//Message
Vue.component('message-component', require('./components/messages/MessageComponent').default);
Vue.component('message-item-component', require('./components/messages/MessageItemComponent').default);

//Archives
Vue.component('archive-component', require('./components/archives/ArchiveComponent').default);
Vue.component('archive-list-component', require('./components/archives/ListComponent').default);
Vue.component('archive-form-component', require('./components/archives/FormComponent').default);


//Costs
Vue.component('cost-component', require('./components/costs/CostComponent').default);
Vue.component('cost-list-component', require('./components/costs/ListComponent').default);
Vue.component('cost-form-component', require('./components/costs/FormComponent').default);


//Generics
Vue.component('mfile-upload', require('./components/generics/MFileUpload').default);
Vue.component('spa', require('./components/SPAComponent').default);

// Vue.component('select2-vue-component', require('./components/Select2VueComponent').default);
// Vue.component('select2-vue-component', require('./components/Select2VueComponent').default);

Vue.prototype.URL_BASE = URL_BASE

const app = new Vue({
    el: '#app',
    store,
});


$('#app').tooltip({
    //selector: '[data-toggle="tooltip"]'
    //selector: "[data-tooltip=tooltip]",
});
