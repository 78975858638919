import {fromJson, searchOffline, URL_BASE} from "./helper";
import store from "../store";


class ChecklistModel {


    constructor() {

        this.description = null;
        this.observations = null;

    }


    static _module() {
        return URL_BASE + "/checklist_models";
    }


    static fromJson(object) {
        return fromJson((new ChecklistModel()), object);
    }

    static async find(search) {
        let url = this._module() + "/find";
        var collection = new Array()

        if (!navigator.onLine){
            return await searchOffline('checklist');
        }

        await axios.get(url, {search: search})
            .then((response) => {
                collection = response.data.map(function (tank) {
                    return {
                        id: tank.id,
                        description: tank.description,
                    }
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

}

export default ChecklistModel;
