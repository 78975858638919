import {fromJson, URL_BASE} from "./helper";
import store from "../store";


class Pendency {


    constructor() {

        this.id = null;
        this.attendance_id = null
        this.description = null
        this.user = {}
        this.due = null
        this.status = null
        this.observation = null
        this.images = null
        this.created_at = null

    }


    static _module() {
        return URL_BASE + "/pendencies";
    }

    static fromJson(object) {
        return fromJson((new Pendency()), object);
    }

    destroyURL() {
        return Pendency._module() + "/" + this.id + "/destroy"
    }


    static async list(endpoint = '/', data = {}) {
        data.attendance_id = store.getters.attendance.id

        let url = this._module() + endpoint;
        var collection = new Array()
        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return Pendency.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }
}

export default Pendency;
