import axios from 'axios'
import {URL_BASE} from "./helper";
import store from "../store";

class UserModel {

    constructor() {

        this.id = null;
        this.name = null;

    }

    static _module() {
        return URL_BASE + "/users";
    }


    static fromJson(object) {
        let user = new UserModel()
        user.id = object.id
        user.name = object.name
        return user;
    }


    static async list(endpoint = '/list') {
        let url = this._module() + endpoint;
        var collection = new Array()
        await axios.get(url)
            .then((response) => {
                collection = response.data.data.map(function (user) {
                    return UserModel.fromJson(user)
                })

            })
            .catch(error => {

                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

    static async find(search, types = null) {
        let url = this._module() + "/find";
        var collection = new Array()
        await axios.get(url, {params:{search: search, type: types, client_id : store.getters.attendance.client.id}})
            .then((response) => {
                collection = response.data.map(function (model) {
                    return {
                        id: model.id,
                        description: model.name,
                    }
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }


}

export default UserModel;
