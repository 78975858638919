<template>
    <div>
        <div class="wrapper wrapper-content">
            <div class="container-fluid">

                <small v-if="error" class="p-2 alert-danger">{{ error }}</small>
                <form @submit.prevent="saveAttendance">
                    <div class="form-group">
                        <label>Cliente <span>*</span></label>
                        <v-select
                                  :options="clients"
                                  id="client_id"
                                  :reduce="option => option.id"
                                  label="description"
                                  placeholder="Escolha um Cliente"
                                  v-model="fields.client_id">
                            <template slot="no-options">
                                Pesquise por clientes
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group @if ($errors->has('date_hour_start')) has-error @endif">
                        <label>Data de Início <span>*</span></label>
                        <input class="form-control form-control-lg" name="date_hour_start"
                               id="date_hour_start" type="date"
                               v-model="fields.date_hour_start">
                    </div>
                    <div class="form-group @if ($errors->has('date_hour_end')) has-error @endif">
                        <label>Data Término <span>*</span></label>
                        <input name="date_hour_end"
                               id="date_hour_end" class="form-control form-control-lg" type="date"
                               v-model="fields.date_hour_end">
                    </div>
                    <div class="form-group @if ($errors->has('hours')) has-error @endif">
                        <label>Horas <span>*</span></label>
                        <input ame="hours"
                               id="hours" class="form-control form-control-lg" type="time"
                               v-model="fields.hours">
                    </div>
                    <div class="form-group">
                        <label>Responsável Cliente</label>
                        <input name="companion"
                               id="companion" class="form-control form-control-lg" v-model="fields.companion" type="text">
                    </div>
                    <div class="form-group">
                        <label>Observações</label>
                        <textarea type="textl" class="form-control" name="observations"
                                  id="observations" v-model="fields.observations"> </textarea>
                    </div>
                    <div class="form-group">
                        <label>Mecânico <span>*</span></label>
                        <v-select
                            :options="mechanics"
                            id="mechanic_id"
                            :reduce="option => option.id"
                            label="description"
                            placeholder="Escolha um Mecânico"
                            v-model="fields.mechanic_id">
                            <template slot="no-options">
                                Pesquise por Mecânico
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group">
                        <label>Consultor <span>*</span></label>
                        <v-select
                            :options="consulters"
                            id="consulter_id"
                            :reduce="option => option.id"
                            label="description"
                            placeholder="Escolha um Consultor"
                            v-model="fields.consulter_id">
                            <template slot="no-options">
                                Pesquise por Consultor
                            </template>
                        </v-select>
                    </div>
                    <div class="form-group @if ($errors->has('latitude')) has-error @endif">
                        <input type="hidden" name="latitude" id="latitude" class="form-control"
                               field.longitude>
                    </div>
                    <div class="form-group @if ($errors->has('longitude')) has-error @endif">
                        <input type="hidden" name="longitude" id="longitude"
                               class="form-control"
                               field.latitude>
                    </div>
                    <button class="btn btn-lg btn-block btn-success mt-4" id="bt_salvar" type="submit">Cadastrar
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
export default {
    name: "AttendanceFormComponent",
    components: {vSelect},
    data() {
        return {
            attendances: [],
            error: null,
            clients: [],
            mechanics: [],
            consulters: [],
            fields: {
                latitude: null,
                longitude: null,
            }
        }
    },
    methods: {


        getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition, showError);
            } else {
                this.error = "A Geolocalização não é permitida neste navegador";
            }
            var vm = this;

            function showPosition(position) {

                vm.fields.latitude = position.coords.latitude
                vm.fields.longitude = position.coords.longitude

            }

            function showError(error) {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        vm.error = "O usuário negou a solicitação de geolocalização. Permita a localização para criar um atendimento"
                        break;
                    case error.POSITION_UNAVAILABLE:
                        vm.error = "As informações de localização não estão disponíveis."
                        break;
                    case error.TIMEOUT:
                        vm.error = "O pedido para obter a localização do usuário expirou."
                        break;
                    case error.UNKNOWN_ERROR:
                        vm.error = "Ocorreu um erro desconhecido na geolocalização!"
                        break;
                }
            }


        },
        async saveAttendance() {
            var dbAttendance = new PouchDB('ga-attendances');
            var attendance = this.fields;
            attendance['_id'] = `off_${attendance.client_id}`
            await dbAttendance.put(attendance, function callback(err, result) {
                if (!err) {
                    alert('Salvo com sucesso!')
                    setTimeout(function () {
                        top.location.href = '/panel'
                    }, 500)
                    console.log("result", result)
                } else {
                    alert('Erro ao salvar o atendimento')
                    console.error(err);
                }
            });
        }



    },
    mounted() {
        this.getLocation()
        var vm  = this;

        var db = new PouchDB('ga-db');

        async function getClients() {
            let clients = [];
            await db.allDocs({include_docs: true, descending: true, key: 'clients'}, function (err, doc) {
                clients = doc.rows[0].doc.data
            });
            clients = clients.map((client) => {
                client["description"] = client.social_name + '-' + client.name
                return client;
            })

            vm.clients =  clients;

        }

        async function getMechanics() {
            let mechanics = [];
            await db.allDocs({include_docs: true, descending: true, key: 'mechanics'}, function (err, doc) {
                mechanics = doc.rows[0].doc.data
            });

            mechanics = mechanics.map((mechanic) => {
                mechanic["description"] = mechanic.text;
                return mechanic;
            })

            vm.mechanics =  mechanics;

        }

        async function getConsulter() {
            let consulters = [];
            await db.allDocs({include_docs: true, descending: true, key: 'consulters'}, function (err, doc) {
                consulters = doc.rows[0].doc.data
            });

            consulters = consulters.map((consulter) => {
                consulter["description"] = consulter.text;
                return consulter;
            })

            vm.consulters =  consulters;

        }

        getClients()
        getMechanics()
        getConsulter()



    }
}


</script>
<style>

</style>
