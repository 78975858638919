<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="{'ibox':true, 'float-e-margins':true, 'p-3':$store.getters.isMobile}">
                <div class="ibox-title">
                    <h5 :class="{'text-white':$store.getters.isMobile}">Cadastro de pendência</h5>
                </div>
                <div class="ibox-content">


                    <form :method="method" class="form-horizontal" name="frm_pendence"
                          autocomplete="off"
                          enctype="multipart/form-data" @submit.prevent="submit">

                        <!-- inicio dos campos -->

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label>Descrição *</label>
                                <input class="form-control" name="description" v-model.lazy="fields.description"
                                       value=""/>
                                <div v-if="errors && errors.description" class="text-danger">{{
                                        errors.description[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="user_id">Responsável</label>
                                <v-select @search="onSearch"
                                          :options="itemOptions"
                                          id="user_id"
                                          :reduce="option => option.id"
                                          label="description"
                                          placeholder="Escolha um responsável"
                                          v-model="fields.user_id">
                                    <template slot="no-options">
                                        Pesquise por usuários
                                    </template>
                                </v-select>


                                <div v-if="errors && errors.user_id" class="text-danger">{{
                                        errors.user_id[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Data para entrega</label>
                                <input type="datetime-local" name="due"
                                       class="form-control"
                                       v-model="fields.due"/>
                                <div v-if="errors && errors.due" class="text-danger">{{
                                        errors.due[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Status</label>
                                <select name="status" v-model="fields.status"
                                        class="form-control">
                                    <option v-for="(state, index) in $store.getters.pending_status" :value="index">
                                        {{ state }}
                                    </option>
                                </select>
                                <div v-if="errors && errors.status" class="text-danger">{{
                                        errors.status[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Observação</label>
                                <textarea name="observation"
                                          class="form-control"
                                          v-model="fields.observation"></textarea>
                                <div v-if="errors && errors.observation" class="text-danger">{{
                                        errors.due[0]
                                    }}
                                </div>
                            </div>

                            <mfile-upload :images="fields.images" :folder="'pendencies'"
                                          @deletedImage="removeImage"></mfile-upload>


                        </div>


                        <button class="btn btn-primary" id="bt_salvar" type="submit">
                            <i class="fa fa-save"></i>
                            Salvar <span v-if="item">Alterações</span>
                        </button>


                        <!--                        <button @click="submit(true)" class="btn btn-default" id="bt_salvar_adicionar" type="submit">-->
                        <!--                            <i class="fa fa-save"></i>-->
                        <!--                            Salvar e adicionar novo-->
                        <!--                        </button>-->

                        <a @click="$emit('isForm', false)"
                           :class="{'btn':true, 'btn-default':true, 'text-white':$store.getters.isMobile}"
                           id="ln_listar_form">
                            <i class="fa fa-list-ul"></i>
                            Listar
                        </a>

                        <!-- FIM -->
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import moment from 'moment'
import {URL_BASE} from "../../models/helper";
import UserModel from "../../models/UserModel";
import Pendency from "../../models/PendencyModel";

export default {
    components: {vSelect},
    props: {
        item: {
            type: Object,
            required: false
        }
    },
    data() {
        return {

            loaded: true,
            errors: {},
            itemOptions: [],
            fields: {},
            method: "post",
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false
            }
        }
    },
    created() {

    },
    methods: {
        moment: moment,
        async onSearch(search, loading) {
            loading(true)
            this.itemOptions = await UserModel.find(search, null);
            loading(false)
        },
        async submit(newRecord = false) {

            //this.method = this.item ? 'put': 'post';

            let form = document.forms.namedItem('frm_pendence');
            let Data = new FormData(form);

            Data.append("user_id", this.fields.user_id)
            Data.append("attendance_id", this.$store.getters.attendance.id);

            let url = Pendency._module();

            if (this.item) {
                this.method = 'put';
                Data.append("id", this.item.id)
                Data.append("_method", this.method)
            }

            return this.save(url, Data, newRecord);
        },
        save(url, data, newRecord = false) {
            this.errors = {};
            block()

            axios.post(url, data)
                .then(() => {
                    showMessage('s', 'Salvo com sucesso');
                    this.$emit('isForm', false)

                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(() => unBlock())
        },
        removeImage(index) {
            this.fields.images.splice(index, 1);
        }

    },
    mounted() {
        if (this.item) {
            this.fields = this.item;
            this.fields.due = moment(this.fields.due).format("YYYY-MM-DDTHH:mm")
            this.itemOptions.push(
                {
                    id: this.item.user.id,
                    description: this.item.user.name
                }
            )
            this.fields.user_id = this.item.user.id
        }


    }
}

</script>

<style>
@media only screen and (max-width: 600px) {

    .vs__selected {
        color: #fff;
    }

    .vs__search {
        color: white;
    }

    .list-group-item .vs__selected{
        color: #333;
    }
}
</style>
