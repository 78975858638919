<template>
    <div class="col-md-12">

        <div class="row col-md-12" v-if="images &&  images.length > 0">
            <a target="_blank" :href="image.url" class="col-md-2 col-sm-4" v-for="(image, index) in images"
               v-if="(!field && !image.field) || image.field == field"
               style="padding: 2px;">
                <img :src="image.thumbanil" alt="..." style="width: 100%; opacity: 0.5">
                <div>
                    <a @click="selectImage(image)" data-toggle="modal"
                       :data-target="'#' + (modalId ? modalId : 'modalEdit')"
                       class="btn btn-primary fa fa-pencil image-action image-action-edit"></a>
                    <a @click="deleteImage(image, index)" data-toggle="modal"
                       class="btn btn-danger fa fa-trash image-action image-action-delete"></a>
                    <p class="image-description">{{image.description}}</p>
                </div>
            </a>

        </div>

        <div class="row" v-for="(file, index) in files">
            <div :class="{'form-group':true, 'col-md-6':setDescription, 'col-md-12':!setDescription}">
                <label>Imagem <span v-if="field">({{label}})</span>
                    <template v-if="file.handle">
                        <span class='fa' :class="file.uploadClass"></span>
                    </template>
                </label>
                <input :id="'file-upload-'+index" :data-index="index" type="file" name="files[]" @focus="selected=index"
                       @change="onFileChange" class="form-control">
                <input type="hidden" name="fields[]" :data-index="index"
                       :data-delete="modalId ? `files-${modalId}` : 'files-fields'" v-model="field"
                       class="form-control">
                <input type="hidden" :id="'file-name-'+index" name="filename[]" v-model="file.filename"
                       :data-index="index" class="form-control">
                <img v-if="file.filename" :src="getImage(file)" alt="image" :id="'img-'+index"
                     style="max-height: 100px;">
            </div>
            <div class="form-group col-md-6" v-show="setDescription">
                <label>Descrição da imagem</label>
                <input type="text" name="descriptions[]" :required="file.filename ? 'required': false"  :data-index="index" v-model="file.description"
                       class="form-control">
            </div>
        </div>
        <div class="row" v-if="!field">
            <div class="form-group col-md-6">
                <a @click="addFile" :class="{'btn':true, 'text-white':$store.getters.isMobile}">
                <span class="fa fa-plus"></span> Imagem
                </a>
            </div>
        </div>

        <div :class="{'modal':true,'inmodal':true, 'fade':true}" :id="modalId ? modalId : 'modalEdit'" tabindex="-1"
             role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-body">
                        <textarea class="form-control" rows="5" v-model="image.description"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
                        <button @click="editImage" type="button" class="btn btn-primary">Salvar</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import ServiceImageModel from "../../models/ServiceImageModel";
    import {saveImageOffline} from "../../models/helper";

    export default {
        name: "MFileUpload",
        props: {
            images: {
                type: Array
            },
            modalId: {
                type: String,
                default: null,
            },
            field: {
                type: String,
                default: null
            },
            folder: {
                type: String,
                default: 'images'
            },
            label: {
                type: String,
                default: '-'
            },
            setDescription: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                files: [
                    {
                        anexo: '',
                        description: '',
                        uploadClass: null,
                        handle: false,
                        filename: null,
                    }
                ],
                uploadClass: {
                    refresh: {'fa-refresh': true, 'text-info': true, 'rotate': true},
                    success: {'fa-check': true, 'text-success': true},
                    error: {'fa-close': true, 'text-danger': true},
                },
                selected: 0,
                image: {
                    id: null,
                    description: null,
                    label: ''
                },
            }
        },
        methods: {
            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;

                let index = e.target.getAttribute('data-index')

                this.files[index].handle = true
                this.files[index].uploadClass = this.uploadClass.refresh

                var file = files[0];
                if (file.type.match('image.*')) {
                    let app = this
                    this.resizeImage(files[0], function (result) {
                        app.uploadImage(result, file.name, index);
                    });
                } else {
                    this.files[index].uploadClass = this.uploadClass.error
                    showMessage('e', 'Este arquivo não é uma imagem')
                }

                this.files[this.selected].anexo = files[0]
                this.$emit("changeFile", this.files)
                $(e.target).remove();


            },

            addFile() {
                this.files.push({
                    anexo: '',
                    description: '',
                    uploadClass: null,
                    handle: false,
                    filename: null,
                });
                this.$emit("changeFile", this.files)
            },

            selectImage(image) {
                console.log(image)
                this.image = image
            },

            resizeImage(file, callback) {
                let app = this;
                if (window.File && window.FileReader && window.FileList && window.Blob) {
                    // var filesToUploads = document.getElementById('imageFile').files;
                    // var file = filesToUploads[0];
                    if (file) {

                        var reader = new FileReader();
                        // Set the image once loaded into file reader
                        reader.onload = function () {

                            var image = new Image();
                            image.onload = function () {

                                var canvas = document.createElement("canvas");
                                var ctx = canvas.getContext("2d");
                                ctx.drawImage(image, 0, 0);

                                var MAX_WIDTH = 600;
                                var MAX_HEIGHT = 600;
                                var width = image.width;
                                var height = image.height;

                                if (width > height) {
                                    if (width > MAX_WIDTH) {
                                        height *= MAX_WIDTH / width;
                                        width = MAX_WIDTH;
                                    }
                                } else {
                                    if (height > MAX_HEIGHT) {
                                        width *= MAX_HEIGHT / height;
                                        height = MAX_HEIGHT;
                                    }
                                }
                                canvas.width = width;
                                canvas.height = height;
                                var ctx = canvas.getContext("2d");
                                ctx.drawImage(image, 0, 0, width, height);

                                callback(canvas.toDataURL(file.type));
                            }

                            image.src = this.result;
                        }

                        reader.readAsDataURL(file);

                        reader.onabort = function () {
                            showMessage('e', "O upload foi abortado.");
                        }

                        reader.onerror = function () {
                            showMessage('e', "Ocorreu um erro ao ler o arquivo.");
                        }
                    }

                } else {
                    showMessage('e', 'The File APIs are not fully supported in this browser.');
                }
            },
            async uploadImage(dataUrl, filename, index) {
                let app = this

                if (!navigator.onLine) {
                   let image = await saveImageOffline({
                        dataEncoded: dataUrl,
                        filename: filename,
                        folder: app.folder
                    });
                    app.files[index].uploadClass = this.uploadClass.success
                    app.files[index].filename = image
                    app.files[index].anexo = dataUrl
                    return;
                }
                axios.post(ServiceImageModel.uploadURL(), {
                    dataEncoded: dataUrl,
                    filename: filename,
                    folder: app.folder
                })
                    .then((response) => {
                        app.files[index].uploadClass = this.uploadClass.success
                        app.files[index].filename = response.data.data.filename
                        // showMessage('s', 'Upload concluído');
                    })
                    .catch(error => {

                        app.files[index].uploadClass = this.uploadClass.error

                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }

                        if (error.response.status === 401) {
                            showMessage('w', error.response.data.server_error);
                        }

                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors || {};
                            showMessage('w', 'Preencha todos os campos obrigatórios.');
                        }
                    })
                    .finally(() => unBlock())
            },
            dataURLToBlob(dataURL) {
                var BASE64_MARKER = ';base64,';
                if (dataURL.indexOf(BASE64_MARKER) == -1) {
                    var parts = dataURL.split(',');
                    var contentType = parts[0].split(':')[1];
                    var raw = parts[1];

                    return new Blob([raw], {type: contentType});
                }

                var parts = dataURL.split(BASE64_MARKER);
                var contentType = parts[0].split(':')[1];
                var raw = window.atob(parts[1]);
                var rawLength = raw.length;

                var uInt8Array = new Uint8Array(rawLength);

                for (var i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }

                return new Blob([uInt8Array], {type: contentType});
            },
            editImage() {
                this.errors = {};
                let app = this;
                block()
                axios.put(ServiceImageModel._module(), this.image)
                    .then(() => {
                        showMessage('s', 'Salvo com sucesso');
                        $('#' + (app.modalId ? app.modalId : 'modalEdit')).modal('hide');
                    })
                    .catch(error => {

                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }

                        if (error.response.status === 401) {
                            showMessage('w', error.response.data.server_error);
                        }

                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors || {};
                            showMessage('w', 'Preencha todos os campos obrigatórios.');
                        }
                    })
                    .finally(() => unBlock())
            },
            deleteImage(image, index) {
                this.image = image
                this.image.index = index;
                this.errors = {};
                if (confirm("Deseja deletar esta imagem?")) {
                    block()
                    axios.delete((ServiceImageModel.fromJson(this.image).destroyURL()))
                        .then(() => {
                            showMessage('s', 'Imagem deletada com sucesso');
                            this.$emit('deletedImage', this.image.index)
                        })
                        .catch(error => {

                            if (error.response.status === 500) {
                                showMessage('e', 'Não foi possível completar requisição');
                            }

                            if (error.response.status === 401) {
                                showMessage('w', error.response.data.server_error);
                            }

                            if (error.response.status === 400) {
                                this.errors = error.response.data.errors || {};
                                showMessage('w', 'Preencha todos os campos obrigatórios.');
                            }
                        })
                        .finally(() => unBlock())
                }
            },
            getImage(file){
                if (!navigator.onLine){
                    return file.anexo;
                }
                return this.$store.getters.url + file.filename
            }
        }
    }
</script>

<style scoped>

    .image-action {
        position: absolute;
        top: 0;
        color: white;
    }

    .image-action-delete {
        right: 1%;
    }

    .image-action-edit {
        right: 25%;
    }

    .image-description {
        width: 100%;
        height: 30%;
        overflow-y: scroll;
        position: absolute;
        bottom: 0;
        text-align: center;
        color: #1b1e21;
    }

    .text-primary {
        color: #1ab394;
    }

    .rotate {

        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
