import {fromJson, getCollectionOffline, URL_BASE} from "./helper";
import store from "../store";


class ServiceFuelQualityModel {


    constructor() {

        this.id = null;
        this.tank = null;
        this.water_detection_test = null;
        this.visual_aspect_test = null;
        this.density = null;
        this.temperature = null;
        this.result = null;
        this.sample_origin = null;
        this.images = [];
        this.created_at = null;

    }


    static _module() {
        return URL_BASE + "/service_fuel_quality";
    }

    static fromJson(object) {
        return fromJson((new ServiceFuelQualityModel()), object);
    }

    destroyURL() {
        return ServiceFuelQualityModel._module() + "/" + this.id + "/destroy"
    }


    static async list(endpoint = '/', data = {}) {
        data.attendance_id = store.getters.attendance.id

        let url = this._module() + endpoint;
        var collection = new Array()

        if (!navigator.onLine){
            return getCollectionOffline('fuel_quality',data.attendance_id, this)
        }

        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return ServiceFuelQualityModel.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }
}

export default ServiceFuelQualityModel;
