import {fromJson, searchOffline, URL_BASE} from "./helper";
import store from "../store";


class TankModel {


    constructor() {

        this.client_id = null;
        this.description = null;
        this.maximum_capacity = null;
        this.minimum_stock = null;
        this.fuel_id = null;

    }


    static _module() {
        return URL_BASE + "/tanks";
    }


    static fromJson(object) {
        return fromJson((new TankModel()), object);
    }

    static async list(endpoint = '/all') {
        let url = this._module() + endpoint;
        var collection = new Array()
        await axios.get(url, {
            params: {
                client_id: store.getters.attendance.client.id
            }
        })
            .then((response) => {
                collection = response.data.data.map(function (user) {
                    return TankModel.fromJson(user)
                })

            })
            .catch(error => {

                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

    static async find(search) {
        let url = this._module() + "/find";
        var collection = new Array()
        if (!navigator.onLine){
            return await searchOffline('tanks', store.getters.attendance.client.id);
        }
        await axios.get(url, {
            params: {
                search: search,
                client_id: store.getters.attendance.client.id
            }
        })
            .then((response) => {
                collection = response.data.map(function (tank) {
                    return {
                        id: tank.id,
                        description: tank.description,
                    }
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

}

export default TankModel;
