<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="{'ibox':true, 'float-e-margins':true, 'p-3':$store.getters.isMobile}">
                <div class="ibox-title">
                    <h5 :class="{'text-white':$store.getters.isMobile}">Cadastro de Análise de qualidade</h5>
                </div>
                <div class="ibox-content">


                    <form :method="method" class="form-horizontal" name="frm_fuel_quality" id="frm_save"
                          autocomplete="off"
                          @submit.prevent="submit">

                        <!-- inicio dos campos -->

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label>Tanque *</label>
                                <v-select @search="tanksSearch"
                                          :options="itemOptions"
                                          :reduce="option => option.id"
                                          label="description"
                                          placeholder="Escolha um tanque"
                                          v-model="fields.tank_id">
                                    <template slot="no-options">
                                        Pesquise por tanques
                                    </template>
                                </v-select>


                                <div v-if="errors && errors.tank_id" class="text-danger">{{ errors.tank_id[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Teste de detecção de água</label>
                                <select name="water_detection_test" v-model="fields.water_detection_test"
                                        class="form-control">
                                    <option v-for="(state, index) in $store.getters.laboratory_test" :value="index">
                                        {{state}}
                                    </option>
                                </select>
                                <div v-if="errors && errors.water_detection_test" class="text-danger">{{
                                    errors.water_detection_test[0]
                                    }}
                                </div>
                            </div>
                            <mfile-upload :images="fields.images" modal-id="model_water_detection_test" :folder="'service_fuel_quality'"
                                          field="water_detection_test" label="Teste de detecção de água"
                                          @deletedImage="removeImage"></mfile-upload>

                            <div class="form-group col-md-12">
                                <label>Teste de aspecto visual</label>
                                <select name="visual_aspect_test" v-model="fields.visual_aspect_test"
                                        class="form-control">
                                    <option v-for="(state, index) in $store.getters.visual_aspect" :value="index">
                                        {{state}}
                                    </option>
                                </select>
                                <div v-if="errors && errors.visual_aspect_test" class="text-danger">{{
                                    errors.visual_aspect_test[0]
                                    }}
                                </div>
                            </div>
                            <mfile-upload :images="fields.images" modal-id="modal_visual_aspect_test" :folder="'service_fuel_quality'"
                                          field="visual_aspect_test" label="Teste de aspecto visual"
                                          @deletedImage="removeImage"></mfile-upload>

                            <div class="form-group col-md-12">
                                <label>Densidade * </label>
                                <input type="text" name="density"
                                       class="form-control"
                                       v-model="fields.density">
                                <div v-if="errors && errors.density" class="text-danger">{{
                                    errors.density[0]
                                    }}
                                </div>
                            </div>
                            <mfile-upload :images="fields.images" modal-id="modal_density" :folder="'service_fuel_quality'" field="density"
                                          label="Densidade"
                                          @deletedImage="removeImage"></mfile-upload>

                            <div class="form-group col-md-12">
                                <label>Temperatura *</label>
                                <input type="text" name="temperature" class="form-control"
                                       v-model="fields.temperature">
                                <div v-if="errors && errors.temperature" class="text-danger">{{
                                    errors.temperature[0]
                                    }}
                                </div>
                            </div>
                            <mfile-upload :images="fields.images" modal-id="modal_temperature" :folder="'service_fuel_quality'" field="temperature"
                                          label="Temperatura"
                                          @deletedImage="removeImage"></mfile-upload>

                            <div class="form-group col-md-12">
                                <label>Resultado * </label>
                                <input type="text" name="result" class="form-control"
                                       v-model="fields.result">
                                <div v-if="errors && errors.result" class="text-danger">{{
                                    errors.result[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Origem da amostra * </label>
                                <input type="text" name="sample_origin" class="form-control"
                                       v-model="fields.sample_origin">
                                <div v-if="errors && errors.sample_origin" class="text-danger">{{
                                    errors.sample_origin[0]
                                    }}
                                </div>
                            </div>

                            <mfile-upload :images="fields.images" :folder="'service_fuel_quality'"
                                          @deletedImage="removeImage"></mfile-upload>


                        </div>


                        <button class="btn btn-primary" id="bt_salvar" type="submit">
                            <i class="fa fa-save"></i>
                            Salvar <span v-if="item">Alterações</span>
                        </button>


                        <!--                        <button @click="submit(true)" class="btn btn-default" id="bt_salvar_adicionar" type="submit">-->
                        <!--                            <i class="fa fa-save"></i>-->
                        <!--                            Salvar e adicionar novo-->
                        <!--                        </button>-->

                        <a @click="$emit('isForm', false)"  :class="{'btn':true, 'btn-default':true, 'text-white':$store.getters.isMobile}" id="ln_listar_form">
                            <i class="fa fa-list-ul"></i>
                            Listar
                        </a>

                        <!-- FIM -->
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select';
    import moment from 'moment'
    import {saveOffline, URL_BASE} from "../../../models/helper";
    import FuelPumpModel from "../../../models/FuelPumpModel";
    import ServiceFuelStockAuditModel from "../../../models/ServiceFuelStockAuditModel";
    import ServiceFuelQualityModel from "../../../models/ServiceFuelQualityModel";
    import TankModel from "../../../models/TankModel";

    export default {
        name: "ServiceFuelQualityFormComponent",
        components: {vSelect},
        props: {
            item: {
                type: Object,
                required: false
            }
        },
        data() {
            return {

                loaded: true,
                errors: {},
                itemOptions: [],
                fields: {},
                method: "post",
            }
        },
        created() {
            if (this.item) {
                this.fields = {}
            }
        },
        methods: {
            moment: moment,
            async tanksSearch(search, loading) {
                loading(true)
                this.itemOptions = await TankModel.find(search);
                loading(false)
            },
            async submit(newRecord = false) {

                //this.method = this.item ? 'put': 'post';

                let form = document.forms.namedItem('frm_fuel_quality');
                let Data = new FormData(form);
                Data.append("tank_id", this.fields.tank_id)
                Data.append("attendance_id", this.$store.getters.attendance.id);

                let url = ServiceFuelQualityModel._module();

                if (this.item) {
                    this.method = 'put';
                    Data.append("id", this.item.id)
                    Data.append("_method", this.method)
                }

                // Data.set('result', parseFloat(Data.get('result').toString().replace(',','.')));
                // Data.set('density', parseFloat(Data.get('density').toString().replace(',','.')));

                return this.save(url, Data, newRecord);
            },
           async save(url, data, newRecord = false) {
               if (!navigator.onLine) {
                   await saveOffline('fuel_quality', data);
                   this.$emit('isForm', false)
                   return;
               }
                this.errors = {};
                block()

                axios.post(url, data)
                    .then(() => {
                        showMessage('s', 'Salvo com sucesso');
                        this.$emit('isForm', false)


                    })
                    .catch(error => {
                        if (error.response.hasOwnProperty('status')) {

                            if (error.response.status === 500) {
                                showMessage('e', 'Não foi possível completar requisição');
                            }


                            if (error.response.status === 401) {
                                showMessage('w', error.response.data.server_error);
                            }

                            if (error.response.status === 400) {
                                this.errors = error.response.data.errors || {};
                                showMessage('w', 'Preencha todos os campos obrigatórios.');
                            }
                        }
                    })
                    .finally(() => unBlock())
            },
            removeImage(index) {
                this.fields.images.splice(index, 1);
            }

        },
        mounted() {
            if (this.item) {
                this.fields = this.item;
                this.itemOptions.push(
                    {
                        id: this.item.tank.id,
                        description: this.item.tank.description
                    }
                )
                this.fields.tank_id = this.item.tank.id
            }


        }
    }

</script>

<style scoped>

</style>
