import {fromJson, URL_BASE} from "./helper";
import store from "../store";


class CostModel {


    constructor() {

        this.id = null;
        this.attendance_id = null
        this.description = null
        this.user = {}
        this.file = null
        this.price = null
        this.receipt = null
        this.payment_date = null
        this.due = null
        this.status = null
        this.observations = null
        this.created_at = null

    }


    static _module() {
        return URL_BASE + "/costs";
    }

    static fromJson(object) {
        return fromJson((new CostModel()), object);
    }

    destroyURL() {
        return CostModel._module() + "/" + this.id + "/destroy"
    }


    static async list(endpoint = '/', data = {}) {
        data.attendance_id = store.getters.attendance.id

        let url = this._module() + endpoint;
        var collection = new Array()
        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return CostModel.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }
}

export default CostModel;
