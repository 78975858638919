<template>
    <div class="card">
        <div class="tabs-container">
            <ul class="nav nav-tabs">
                <li :class="{'active':tabs.service1}">
                    <a data-toggle="tab" href="#service1" @click="service='service1'" :aria-expanded="tabs.service1">
                        Aferição de bomba
                    </a>
                </li>
                <li :class="{'active':tabs.service2}">
                    <a data-toggle="tab" href="#service2" @click="service='service2'" :aria-expanded="tabs.service2">
                        Auditoria de estoque de combustível
                    </a>
                </li>
                <li :class="{'active':tabs.service3}">
                    <a data-toggle="tab" href="#service3" @click="service='service3'" :aria-expanded="tabs.service3">
                        Análise de qualidade
                    </a>
                </li>
                <li :class="{'active':tabs.service4}">
                    <a data-toggle="tab" href="#service4" @click="service='service4'" :aria-expanded="tabs.service4">
                        Limpeza de tanque de armazenamento
                    </a>
                </li>
                <li :class="{'active':tabs.service5}">
                    <a data-toggle="tab" href="#service5" @click="service='service5'" :aria-expanded="tabs.service5">
                        Diálises
                    </a>
                </li>
                <li :class="{'active':tabs.service6}">
                    <a data-toggle="tab" href="#service6" @click="service='service6'" :aria-expanded="tabs.service6">
                        Diversos
                    </a>
                </li>
                <li :class="{'active':tabs.service7}">
                    <a data-toggle="tab" href="#service7" @click="service='service7'" :aria-expanded="tabs.service7">
                        Checklist operacional
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div id="service1" :class="{'tab-pane':true, 'active':tabs.service1}">
                <service-pump-standardisation-form-component
                    @isForm="isForm"
                    :item="service1.item"
                    v-if="service1.form">
                </service-pump-standardisation-form-component>
                <service-pump-standardisation-list-component
                    ref="service3_list"
                    :isForm="service1.form"
                    @isForm="isForm"
                    @isEditing="isEditing"
                    v-else>
                </service-pump-standardisation-list-component>
            </div>
            <div id="service2" :class="{'tab-pane':true, 'active':tabs.service2}">
                <service-fuel-stock-audits-form-component
                    @isForm="isForm"
                    :item="service2.item"
                    v-if="service2.form">
                </service-fuel-stock-audits-form-component>
                <service-fuel-stock-audits-list-component
                    ref="service3_list"
                    :isForm="service2.form"
                    @isForm="isForm"
                    @isEditing="isEditing"
                    v-else>
                </service-fuel-stock-audits-list-component>
            </div>
            <div id="service3" :class="{'tab-pane':true, 'active':tabs.service3}">
                <service-fuel-quality-form-component
                    @isForm="isForm"
                    :item="service3.item"
                    v-if="service3.form">
                </service-fuel-quality-form-component>
                <service-fuel-quality-list-component
                    ref="service3_list"
                    :isForm="service3.form"
                    @isForm="isForm"
                    @isEditing="isEditing"
                    v-else>
                </service-fuel-quality-list-component>
            </div>
            <div id="service4" :class="{'tab-pane':true, 'active':tabs.service4}">
                <service-cleaning-storage-tank-form-component
                    @isForm="isForm"
                    :item="service4.item"
                    v-if="service4.form">
                </service-cleaning-storage-tank-form-component>
                <service-cleaning-storage-tank-list-component
                    ref="service4_list"
                    :isForm="service4.form"
                    @isForm="isForm"
                    @isEditing="isEditing"
                    v-else>
                </service-cleaning-storage-tank-list-component>
            </div>
            <div id="service5" :class="{'tab-pane':true, 'active':tabs.service5}">
                <service-dialysi-form-component
                    @isForm="isForm"
                    :item="service5.item"
                    v-if="service5.form">
                </service-dialysi-form-component>
                <service-dialysi-list-component
                    ref="service4_list"
                    :isForm="service5.form"
                    @isForm="isForm"
                    @isEditing="isEditing"
                    v-else>
                </service-dialysi-list-component>
            </div>
            <div id="service6" :class="{'tab-pane':true, 'active':tabs.service6}">
                <service-diverse-form-component
                    @isForm="isForm"
                    :item="service6.item"
                    v-if="service6.form">
                </service-diverse-form-component>
                <service-diverse-list-component
                    ref="service6_list"
                    :isForm="service6.form"
                    @isForm="isForm"
                    @isEditing="isEditing"
                    v-else>
                </service-diverse-list-component>
            </div>
            <div id="service7" :class="{'tab-pane':true, 'active':tabs.service7}">
                <service-checklist-component>
                </service-checklist-component>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "ServiceComponent",
        components: {},
        data() {
            return {
                service: 'service1',
                service1: {
                    form: false,
                    item: null,
                },
                service2: {
                    form: false,
                    item: null,
                },
                service3: {
                    form: false,
                    item: null,
                },
                service4: {
                    form: false,
                    item: null,
                },
                service5: {
                    form: false,
                    item: null,
                },
                service6: {
                    form: false,
                    item: null,
                },
                service7: {
                    form: false,
                    item: null,
                },
                tabs: {
                    service1: false,
                    service2: false,
                    service3: false,
                    service4: false,
                    service5: false,
                    service6: false,
                    service7: false,
                }
            }
        },
        beforeCreate() {
        },

        created() {
            this.tabAction('service1');
        },
        watch: {
            service: function (val, old) {
                this.tabAction(val);
            }
        },
        methods: {
            moment: moment,
            isForm(value, clear = true) {
                this[this.service].form = value
                if (clear) this[this.service].item = null;
            },
            isEditing(value) {
                this[this.service].item = value
                this.isForm(true, false);
            },
            tabAction(tab) {
                this.tabClear()
                this.tabs[tab] = true
            },
            tabClear() {
                this.tabs = {
                    service1: false,
                    service2: false,
                    service3: false,
                    service4: false,
                    service5: false,
                    service6: false,
                    service7: false,
                }
            }
        },
        mounted() {

        }
    }
</script>
<style scoped>
</style>
