<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Cadastro de pendência</h5>
                </div>
                <div class="ibox-content">

                    <form :method="method" class="form-horizontal" name="frm_pendence"
                          autocomplete="off"
                          enctype="multipart/form-data" @submit.prevent="submit">

                        <!-- inicio dos campos -->

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label>Nome *</label>
                                <input class="form-control" name="name" v-model.lazy="fields.name"
                                       value=""/>
                                <div v-if="errors && errors.name" class="text-danger">{{
                                    errors.name[0]
                                    }}
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label>Arquivo </label>
                                <input type="file" class="form-control" name="file" />
                                <div v-if="errors && errors.file" class="text-danger">{{
                                    errors.file[0]
                                    }}
                                </div>
                            </div>

                        </div>

                        <button class="btn btn-primary" id="bt_salvar" type="submit">
                            <i class="fa fa-save"></i>
                            Salvar <span v-if="item">Alterações</span>
                        </button>


                        <!--                        <button @click="submit(true)" class="btn btn-default" id="bt_salvar_adicionar" type="submit">-->
                        <!--                            <i class="fa fa-save"></i>-->
                        <!--                            Salvar e adicionar novo-->
                        <!--                        </button>-->

                        <a @click="$emit('isForm', false)" class="btn btn-default" id="ln_listar_form">
                            <i class="fa fa-list-ul"></i>
                            Listar
                        </a>

                        <!-- FIM -->
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select';
    import moment from 'moment'
    import {URL_BASE} from "../../models/helper";
    import UserModel from "../../models/UserModel";
    import Archive from "../../models/ArchiveModel";

    export default {
        components: {vSelect},
        props: {
            item: {
                type: Object,
                required: false
            }
        },
        data() {
            return {

                loaded: true,
                errors: {},
                itemOptions: [],
                fields: {},
                method: "post",
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false
                }
            }
        },
        created() {

        },
        methods: {
            moment: moment,
            async onSearch(search, loading) {
                loading(true)
                this.itemOptions = await ArchiveModel.find(search, [this.$store.getters.types.ADMIN]);
                loading(false)
            },
            async submit(newRecord = false) {

                //this.method = this.item ? 'put': 'post';

                let form = document.forms.namedItem('frm_pendence');
                let Data = new FormData(form);

                Data.append("attendance_id", this.$store.getters.attendance.id);

                let url = Archive._module();

                if (this.item) {
                    this.method = 'put';
                    Data.append("id", this.item.id)
                    Data.append("_method", this.method)
                }

                return this.save(url, Data, newRecord);
            },
            save(url, data, newRecord = false) {
                this.errors = {};
                block()

                axios.post(url, data)
                    .then(() => {
                        showMessage('s', 'Salvo com sucesso');
                        this.$emit('isForm', false)

                    })
                    .catch(error => {

                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }

                        if (error.response.status === 401) {
                            showMessage('w', error.response.data.server_error);
                        }

                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors || {};
                            showMessage('w', 'Preencha todos os campos obrigatórios.');
                        }
                    })
                    .finally(() => unBlock())
            },

        },
        mounted() {
            // if (this.item) {
            //     this.fields = this.item;
            //     console.log(this.fields)
            //     // this.itemOptions.push(
            //     //     {
            //     //         id: this.item.user.id,
            //     //         description: this.item.user.name
            //     //     }
            //     // )
            //     // this.fields.user_id = this.item.user.id
            // }


        }
    }

</script>

<style scoped>

</style>
