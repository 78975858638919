import {fromJson, searchOffline, URL_BASE} from "./helper";
import store from "../store";


class ClientModel {


    constructor() {

        this.id = null;
        this.name = null;
        this.social_name = null;

    }


    static _module() {
        return URL_BASE + "/clients";
    }


    static fromJson(object) {
        return fromJson((new ClientModel()), object);
    }

    static async find(search, client_id, is_outsourced=false) {
        let url = this._module() + "/find";
        var collection = new Array()

        if (!navigator.onLine){
            if (!is_outsourced) {
                return await searchOffline('clients', client_id ? client_id : store.getters.attendance.client.id);
            }else{
                return await searchOffline('outsourceds', client_id ? client_id : store.getters.attendance.client.id);

            }
        }
        await axios.get(url,
            {
                params: {
                    search: search,
                    client_id: client_id ? client_id : store.getters.attendance.client.id,
                    is_outsourced: is_outsourced

                }
            }
        )
            .then((response) => {
                collection = response.data.map(function (model) {
                    return {
                        id: model.id,
                        description: model.name,
                    }
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

}

export default ClientModel;
