import moment from "moment";

export const URL_BASE = '/api'
export const URL_PANEL = '/panel'

export function fromJson(model, object) {
    for (var name in model) {
        if (object.hasOwnProperty(name)) {
            model[name] = object[name]
        }
    }

    return model
}

export async function saveOffline(collect, formData) {
    formData.delete('_method', formData.get('method'))
    formData.delete('_method')
    var service = {};
    formData.forEach(function (value, key) {
        if (key.indexOf('[]') > -1){
            service[key.substring(0, key.indexOf('[]'))] = formData.getAll(key)
        }else {
            service[key] = value;
        }
    });
    var dbService = new PouchDB('services');

    async function save() {
        service['_id'] = `service_${Math.random()}`
        service['collect'] = collect
        await dbService.put(service, function callback(err, result) {
            if (!err) {
                alert('Salvo com sucesso!')
            } else {
                alert('Erro ao salvar')
                console.error(err);
            }
        });
    }

    await save();
}

export async function saveImageOffline(data) {
    var dbImage = new PouchDB('images');
    async function save() {
        data['_id'] = `image_${Math.random()}`
        data['collect'] = data.folder
        await dbImage.put(data, function callback(err, result) {
            if (!err) {
            } else {
                console.error(err);
            }
        });
    }
    await save();

    return  data['_id'];
}

export async function searchOffline(collect, client_id) {
    var db = new PouchDB('ga-db');
    let collection = [];

    await db.allDocs({include_docs: true, descending: true, key: collect}, function (err, doc) {
        collection = doc.rows[0].doc.data
    });

    if (collect != "fuels" && collect != "checklist") {
        collection = collection.filter(function (el) {
            return el.client_id == client_id
        })
    }


    function formatSearch(collection, id, ...description) {
        collection = collection.map((el) => {
            let text = '';
            description.forEach((e) => {

                text += (el[e]) ? el[e] + ' ' : ''
            })

            return {
                id: el[id],
                description: text
            }
        })

        return collection;
    }

    switch (collect) {
        case 'fuel_pumps':
            return formatSearch(collection, 'id', 'description')

        case 'tanks':
            return formatSearch(collection, 'id', 'description')

        case 'fuels':
            return formatSearch(collection, 'id', 'description')
        case 'automotor':
            return formatSearch(collection, 'id', 'name', 'board', 'model')

        case 'clients':
            return formatSearch(collection, 'id', 'name', 'social_name')

        case 'outsourceds':
            return formatSearch(collection, 'id',  'social_name')

        case 'checklist':
            return formatSearch(collection, 'id',  'description')



    }

    return collection;

}

export async function getCollectionOffline(collect, attendance_id, model) {
    var db = new PouchDB('services');
    let collection = [];
    await db.allDocs({include_docs: true, descending: true}, function (err, doc) {
        collection = doc.rows
    });

    collection = collection.filter(function (el) {
        return el.doc.collect == collect && el.doc.attendance_id == attendance_id
    })

    async function map() {
        return Promise.all(collection.map(async function (el) {

            el.doc['id'] = el.doc._id;

            if (el.doc.hasOwnProperty('fuel_pump_id')) {
                el.doc['fuel_pump'] = await getById(el.doc.fuel_pump_id, "fuel_pumps");
            }

            if (el.doc.hasOwnProperty('tank_id')) {
                el.doc['tank'] = await getById(el.doc.tank_id, "tanks");
            }

            if (el.doc.hasOwnProperty('fuel_id')) {
                el.doc['fuel'] = await getById(el.doc.fuel_id, "fuels");
            }

            if (el.doc.hasOwnProperty('outsource_id')) {
                el.doc['outsource'] = await getById(el.doc.outsource_id, "outsourceds");
            }

            if (el.doc.hasOwnProperty('automotor_id')) {
                el.doc['automotor'] = await getById(el.doc.automotor_id, "automotor");
            }

            if (!el.doc.hasOwnProperty('created_at') || el.doc.created_at == null) {
                el.doc['created_at'] = moment(new Date()).format('DD/MM/YYYY')
            }


            return model.fromJson(el.doc)
        }));
    }

    collection = await map()

    return collection;

}

export async function getById(id, collect) {
    var db = new PouchDB('ga-db');
    let collection = [];
    await db.allDocs({include_docs: true, descending: true, key: collect}, function (err, doc) {
        collection = doc.rows[0].doc.data
    });
    collection = collection.filter(function (el) {
        return el.id == id
    })
    let item = collection.length > 0 ? collection[0] : null;

    return item;
}

export async function getByLabel(label, value, collect) {
    var db = new PouchDB('ga-db');
    let collection = [];
    await db.allDocs({include_docs: true, descending: true, key: collect}, function (err, doc) {
        collection = doc.rows[0].doc.data
    });
    collection = collection.filter(function (el) {
        return el[label] == value
    })
    let item = collection.length > 0 ? collection[0] : null;

    return item;
}

export async function getServiceByLabel(label, value, collect, model = null) {
    var db = new PouchDB('services');
    let collection = [];
    await db.allDocs({include_docs: true, descending: true}, function (err, doc) {
        collection = doc.rows
    });

    collection = collection.filter(function (el) {
        return el.doc.collect == collect &&  el.doc[label] == value
    })

    async function map() {
        return Promise.all(collection.map(async function (el) {

            el.doc['id'] = el.doc._id;

            if (el.doc.hasOwnProperty('fuel_pump_id')) {
                el.doc['fuel_pump'] = await getById(el.doc.fuel_pump_id, "fuel_pumps");
            }

            if (el.doc.hasOwnProperty('tank_id')) {
                el.doc['tank'] = await getById(el.doc.tank_id, "tanks");
            }

            if (el.doc.hasOwnProperty('fuel_id')) {
                el.doc['fuel'] = await getById(el.doc.fuel_id, "fuels");
            }

            if (el.doc.hasOwnProperty('outsource_id')) {
                el.doc['outsource'] = await getById(el.doc.outsource_id, "outsourceds");
            }

            if (el.doc.hasOwnProperty('automotor_id')) {
                el.doc['automotor'] = await getById(el.doc.automotor_id, "automotor");
            }

            if (!el.doc.hasOwnProperty('created_at') || el.doc.created_at == null) {
                el.doc['created_at'] = moment(new Date()).format('DD/MM/YYYY')
            }
            if (model) {
                return model.fromJson(el.doc)
            }
            return el.doc
        }));
    }

    collection = await map()

    let item = collection.length > 0 ? collection[0] : null;

    return item;
}
