import {fromJson, getCollectionOffline, URL_BASE, URL_PANEL} from "./helper";
import store from "../store";


class ServiceCleaningStorageTankModel {


    constructor() {

        this.id = null;
        this.tank = null;
        this.initial_volume = null
        this.final_volume = null
        this.loss = 0
        this.approximate_sludge_volume = null
        this.images = []
        this.created_at = null;

    }


    static _module(panel = false) {
        return (panel ? URL_PANEL : URL_BASE) + "/service_cleaning_storage_tanks";
    }

    static fromJson(object) {
        return fromJson((new ServiceCleaningStorageTankModel()), object);
    }

    destroyURL() {
        return ServiceCleaningStorageTankModel._module() + "/" + this.id + "/destroy"
    }

    exportPDF() {
        return ServiceCleaningStorageTankModel._module(true) + "/" + this.id + "/pdf/export"
    }

    static async list(endpoint = '/', data = {}) {
        data.attendance_id = store.getters.attendance.id

        if (!navigator.onLine){
            return getCollectionOffline('cleaning_storage_tank',data.attendance_id, this)
        }

        let url = this._module() + endpoint;
        var collection = new Array()
        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return ServiceCleaningStorageTankModel.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }
}

export default ServiceCleaningStorageTankModel;
