<template>
    <div class="card ibox mb-0">

        <div class="ibox-title" id="checklist">
            <h4 v-if="checklist">{{ checklist.description }}</h4>
            <h4 v-else>Checklist</h4>
        </div>

        <div class="ibox-content">

            <div class="m-b-lg p-2">
                <form v-if="!checklist" v-on:submit.prevent>
                    <div class="row align-items-center" v-if="$store.getters.attendance.is_open">
                        <div class="col-md-10">
                            <v-select required @search="onSearch"
                                      :options="itemOptions"
                                      :reduce="option => option.id"
                                      label="description"
                                      placeholder="Escolha um modelo de checklist"
                                      v-model="checklist_model_id">
                            </v-select>
                            <div v-if="errors && errors.checklist_model_id" class="text-danger">{{
                                    errors.checklist_model_id[0]
                                }}
                            </div>
                        </div>
                        <div class="col-md-2 mt-2">
                            <button @click="createCheckList" class="btn btn-block btn-primary">
                                <i class="fa fa-plus"></i> Criar Checklist
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <template v-if="checklist" data-name="MODALS">
                <div :class="{'modal':true,'inmodal':true, 'fade':true}" id="modalCheckListImagem"
                     tabindex="-1"
                     role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <b>Imagens</b> {{ item.index }} - {{ item.description }}
                            </div>
                            <div class="modal-body">
                                <form :method="method" class="form-horizontal" name="frm_image_checklist"
                                      autocomplete="off"
                                      enctype="multipart/form-data" @submit.prevent="submitImages">

                                    <mfile-upload :images="item.images" :folder="'checklists'"
                                                  @deletedImage="removeImage"
                                                  :field="item.index"></mfile-upload>

                                    <div class="form-group col-md-12">
                                        <button class="btn btn-primary" type="submit">
                                            <i class="fa fa-save"></i>
                                            Salvar Imagem
                                        </button>
                                    </div>

                                </form>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="{'modal':true,'inmodal':true, 'fade':true}" id="modalCheckListPendenciaCreate"
                     tabindex="-1"
                     role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <b>Nova Pendência</b> {{ pendence.index }} - {{ pendence.description }}
                            </div>
                            <div class="modal-body">
                                <form :method="method" class="form-horizontal" name="frm_pendence_checklist"
                                      autocomplete="off"
                                      @submit.prevent="submitPendence">

                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>Descrição *</label>
                                            <input class="form-control" name="description"
                                                   v-model.lazy="pendence.description"
                                                   value=""/>
                                            <div v-if="errors && errors.description" class="text-danger">{{
                                                    errors.description[0]
                                                }}
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label for="user_id">Responsável</label>
                                            <v-select
                                                :options="usersAdmin"
                                                id="user_id"
                                                :reduce="option => option.id"
                                                label="description"
                                                placeholder="Escolha um responsável"
                                                v-model="pendence.user_id">
                                                <template slot="no-options">
                                                    Pesquise por usuários
                                                </template>
                                            </v-select>


                                            <div v-if="errors && errors.user_id" class="text-danger">{{
                                                    errors.user_id[0]
                                                }}
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label>Data para entrega</label>
                                            <input type="datetime-local" name="due"
                                                   class="form-control"
                                                   v-model="pendence.due"/>
                                            <div v-if="errors && errors.due" class="text-danger">{{
                                                    errors.due[0]
                                                }}
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label>Status</label>
                                            <select name="status" v-model="pendence.status"
                                                    class="form-control">
                                                <option v-for="(state, index) in $store.getters.pending_status"
                                                        :value="index">
                                                    {{ state }}
                                                </option>
                                            </select>
                                            <div v-if="errors && errors.status" class="text-danger">{{
                                                    errors.status[0]
                                                }}
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label>Observação</label>
                                            <textarea name="observation"
                                                      class="form-control"
                                                      v-model="pendence.observation"></textarea>
                                            <div v-if="errors && errors.observation" class="text-danger">{{
                                                    errors.due[0]
                                                }}
                                            </div>
                                        </div>


                                    </div>

                                    <div class="form-group col-md-12">
                                        <button class="btn btn-primary" type="submit">
                                            <i class="fa fa-save"></i>
                                            Salvar Pendência
                                        </button>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </template>

            <div v-if="checklist" class="table-responsive">
                <div class="desktop">
                    <table class="table table-bordered table-hover">

                        <thead>
                        <tr class="active">
                            <th rowspan="2">Ações</th>
                            <th rowspan="2">#</th>
                            <th rowspan="2">Descrição</th>
                            <th colspan="3">Item conforme?</th>
                            <th width="30%" rowspan="2">Comentário</th>
                        </tr>
                        <tr class="active">
                            <th>Sim</th>
                            <th>Não</th>
                            <th>N/A</th>
                        </tr>

                        </thead>
                        <tbody>
                        <template v-for="(topic, index) in checklist.topics">
                            <tr class="active topic">
                                <td></td>
                                <td>{{ index + 1 }}</td>
                                <th colspan="5">{{ topic.description }}</th>
                            </tr>
                            <template v-for="(question, i) in topic.items">
                                <tr>
                                    <td style="text-align: center">
                                        <div class="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button"
                                                    class="btn btn-default dropdown-toggle"
                                                    data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                Ações
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                <template v-if="$store.getters.attendance.is_open">
                                                    <a @click="createPendence(question, `${index+1}.${i+1}`)"
                                                       data-toggle="modal"
                                                       data-target="#modalCheckListPendenciaCreate"
                                                       class="dropdown-item">Adicionar
                                                        Pendência
                                                    </a>
                                                    <a @click="() => {item = question; item.index = `${index+1}.${i+1}`}"
                                                       data-toggle="modal" data-target="#modalCheckListImagem"
                                                       class="dropdown-item">Imagens
                                                    </a>
                                                </template>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ index + 1 }}.{{ i + 1 }}</td>
                                    <td>{{ question.description }}</td>
                                    <td>
                                        <label>
                                            <input type="radio" value="YES"
                                                   :disabled="!$store.getters.attendance.is_open"
                                                   v-model="question.conforming_item"
                                                   class="">
                                            Sim
                                        </label>
                                    </td>
                                    <td>
                                        <label>
                                            <input type="radio" value="NO"
                                                   :disabled="!$store.getters.attendance.is_open"
                                                   v-model="question.conforming_item"
                                                   class="">
                                            Não
                                        </label>
                                    </td>
                                    <td>
                                        <label>
                                            <input type="radio" value="NA"
                                                   :disabled="!$store.getters.attendance.is_open"
                                                   v-model="question.conforming_item"
                                                   class="">
                                            N/A
                                        </label>
                                    </td>
                                    <td>
                                        <input class="form-control" :readonly="!$store.getters.attendance.is_open"
                                               v-model="question.commentary">
                                    </td>
                                </tr>
                            </template>

                        </template>
                        </tbody>
                    </table>
                </div>
                <div class="mobile">
                    <template v-for="(topic, index) in checklist.topics">
                        <div class="ibox-title">
                            <h4 class="m-0">{{ index + 1 }} - {{ topic.description }}</h4>
                        </div>
                        <div class="ibox-content">
                            <template v-for="(question, i) in topic.items">
                                <div class="ibox mb-3">
                                    <div class="ibox-content">
                                        <h4>{{ index + 1 }}.{{ i + 1 }} - {{ question.description }}</h4>
                                        <div class="answers">
                                            <label class="answer"
                                                   v-bind:class="{ checkedActive: question.conforming_item == 'YES' }">
                                                <input type="radio" value="YES"
                                                       :disabled="!$store.getters.attendance.is_open"
                                                       v-model="question.conforming_item">
                                                Sim
                                            </label>
                                            <label class="answer"
                                                   v-bind:class="{ checkedActive: question.conforming_item == 'NO' }">
                                                <input type="radio" value="NO"
                                                       :disabled="!$store.getters.attendance.is_open"
                                                       v-model="question.conforming_item">
                                                Não
                                            </label>
                                            <label class="answer"
                                                   v-bind:class="{ checkedActive: question.conforming_item == 'NA' }">
                                                <input type="radio" value="NA"
                                                       :disabled="!$store.getters.attendance.is_open"
                                                       v-model="question.conforming_item">
                                                N/A
                                            </label>
                                            <input placeholder="Comentário" class="form-control"
                                                   :readonly="!$store.getters.attendance.is_open"
                                                   v-model="question.commentary">
                                        </div>

                                        <div class="actions">
                                            <template v-if="$store.getters.attendance.is_open">
                                                <a @click="() => { createPendence(question, `${index+1}.${i+1}`)}"
                                                   data-toggle="modal"
                                                   data-target="#modalCheckListPendenciaCreate"
                                                   class="btn btn-outline-light">
                                                    <i class="fa fa-pencil mr-1"></i>
                                                    Pendência
                                                </a>
                                                <a @click="() => {item = question; item.index = `${index+1}.${i+1}`}"
                                                   data-toggle="modal"
                                                   data-target="#modalCheckListImagem"
                                                   class="btn btn-outline-light">
                                                    <i class="fa fa-image mr-1"></i>
                                                    Imagens
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>

                <div v-if="checklist" class="form-group col-md-12">
                    <label>Informações Gerais</label>
                    <textarea class="form-control" :readonly="!$store.getters.attendance.is_open" rows="5"
                              v-model="checklist.observations"></textarea>
                    <div v-if="errors && errors.observations" class="text-danger">{{
                            errors.observations[0]
                        }}
                    </div>
                </div>

                <button v-if="$store.getters.attendance.is_open" @click="save" class="btn  btn-primary">
                    <i class="fa fa-save"></i> Salvar Checklist
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import 'vue-select/dist/vue-select.css';
import moment from 'moment'
import {saveOffline, getServiceByLabel, getById, URL_BASE} from "../../../models/helper";
import ChecklistModel from "../../../models/ChecklistModel";
import vSelect from 'vue-select';
import UserModel from "../../../models/UserModel";
import Pendency from "../../../models/PendencyModel";

export default {

    name: "ChecklistComponent",
    components: {vSelect},
    data() {
        return {

            loaded: true,
            errors: {},
            method: 'POST',
            itemOptions: [],
            usersAdmin: [],
            dataset: [],
            search: {},
            checklist_model_id: null,
            checklist: null,
            item: {},
            pendence: {}
        }
    },
    cerated() {
    },
    methods: {
        moment: moment,
        async onSearch(search, loading) {
            loading(true)
            this.itemOptions = await ChecklistModel.find(search);
            loading(false)
        },
        async getUsers() {
            this.usersAdmin = await UserModel.find('', null);
        },
        async createCheckList() {
            if (confirm("Ao selecionar o template não será mais possível alteração")) {

                if (!navigator.onLine) {
                    let formData = new FormData();
                    formData.append('checklist_model_id', this.checklist_model_id)
                    formData.append('attendance_id', this.$store.getters.attendance.id)
                    await saveOffline('checklist', formData);
                    this.getChecklist();
                }


                this.errors = {};
                block()
                let url = URL_BASE + "/attendance_checklist/store"
                axios.post(url, {
                    checklist_model_id: this.checklist_model_id,
                    attendance_id: this.$store.getters.attendance.id
                })
                    .then(() => {
                        showMessage('s', 'Salvo com sucesso');
                        this.getChecklist();

                    })
                    .catch(error => {

                        if (error.response.status === 500) {
                            showMessage('e', 'Não foi possível completar requisição');
                        }


                        if (error.response.status === 401) {
                            showMessage('w', error.response.data.server_error);
                        }

                        if (error.response.status === 400) {
                            this.errors = error.response.data.errors || {};
                            showMessage('w', 'Preencha todos os campos obrigatórios.');
                        }
                    })
                    .finally(() => unBlock())
            }
        },
        async getChecklist() {
            if (!navigator.onLine) {
                let serviceCheckList = await getServiceByLabel('attendance_id', this.$store.getters.attendance.id, 'checklist')
                console.log(serviceCheckList)
                this.checklist = await getById(serviceCheckList.checklist_model_id, 'checklist');
                return;
            }
            this.errors = {};
            let url = URL_BASE + "/attendance_checklist/checklist"
            axios.post(url, {
                attendance_id: this.$store.getters.attendance.id
            })
                .then((response) => {
                    if (response.data.data.hasOwnProperty('id')) {
                        this.checklist = response.data.data
                    }

                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(() => null)
        },

        async save() {
            this.errors = {};
            if (!navigator.onLine) {
                var dbService = new PouchDB('services');
                var service = this.checklist
                service['attendance_id'] = this.$store.getters.attendance.id
                service['_id'] = `service_${Math.random()}`
                service['collect'] = 'checklist_topics'
                await dbService.put(service, function callback(err, result) {
                    if (!err) {
                        alert('Salvo com sucesso!')
                    } else {
                        alert('Erro ao salvar')
                        console.error(err);
                    }
                });

                this.getChecklist();
            }
            let url = URL_BASE + "/attendance_checklist/update"
            axios.post(url, this.checklist)
                .then((response) => {
                    showMessage('s', 'Salvo com sucesso');
                    this.getChecklist()
                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(() => null)
        },

        async submitImages() {

            let form = document.forms.frm_image_checklist;
            let Data = new FormData(form);
            Data.append("id", this.item.id)
            let url = URL_BASE + `/attendance_checklist/${this.item.id}/images`;
            return this.saveImages(url, Data);


        },
        saveImages(url, data) {
            this.errors = {};
            block()

            axios.post(url, data)
                .then((response) => {
                    showMessage('s', 'Salvo com sucesso');
                    this.item.images = response.data.data.images;
                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(() => unBlock())
        },
        removeImage(index) {
            this.item.images.splice(index, 1);
        },
        createPendence(question, index) {
            this.pendence = JSON.parse(JSON.stringify(question));
            this.pendence.index = index
            this.pendence.status = 'PENDING'
            this.pendence.due = moment(this.date_end).add('2', 'days').format("YYYY-MM-DDTHH:mm")
        },
        async submitPendence() {

            let form = document.forms.namedItem('frm_pendence_checklist');
            let Data = new FormData(form);

            Data.append("user_id", this.pendence.user_id)
            Data.append("description", this.pendence.description)
            Data.append("due", this.pendence.due)
            Data.append("status", this.pendence.status)
            Data.append("attendance_id", this.$store.getters.attendance.id);
            Data.append("item_id", this.pendence.id);

            let url = Pendency._module();

            return this.savePendence(url, Data);
        },
        savePendence(url, data) {
            this.errors = {};
            block()

            axios.post(url, data)
                .then(() => {
                    showMessage('s', 'Salvo com sucesso');
                    this.$emit('isForm', false)

                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(() => unBlock())
        },


    },
    mounted() {
        this.getChecklist();
        this.getUsers();
    }
}

</script>
<style>
span.vs__selected {
    color: #1f1e1e !important;
}
</style>
<style scoped>
.topic {
    background: #f0f0f0;
}

.answers {
    margin: 20px 0;
}

.answers label.answer {
    display: flex;
    align-items: center;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    padding: 10px;
}

.answers label.answer.checkedActive {
    background-color: rgba(144, 238, 144, .1);
    border-color: rgba(144, 238, 144, .4);
}

.answers label.answer +
.answers label.answer {
    margin-top: 5px;
}

.answers label.answer input {
    margin-right: 10px;
}

.desktop {
    display: none;
}

.mobile {
    display: block;
}

@media screen and (min-width: 992px) {
    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }
}


</style>
