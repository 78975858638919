<template>
    <div
        :class="{'chat-message': true, 'left':!$store.getters.isLogado(item.user.id), 'right': $store.getters.isLogado(item.user.id)  }">
        <span class="message-avatar fa fa-user-circle fa-4x"></span>
        <div class="message">
            <a class="message-author" href="#"> {{item.user.name}} </a>
            <span class="message-date">  {{moment(item.created_at).format("DD/MM/YYYY HH:mm")}} </span>
            <span class="message-content">{{item.message}} </span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {

        name: "MessageItemComponent",
        props: {
            item: {
                type: Object
            }
        },
        data() {
            return {}
        },
        methods: {
            moment: moment,
        },
        mounted() {

        }
    }


</script>

<style scoped>
    .message-content {
        overflow-x: hidden;
    }
</style>
