<template>
    <div>
        <div v-if="!$store.getters.isMobile" class="ibox mb-0">
            <div class="ibox-title">
                <h4>Listagem de serviços diversos</h4>
                <div class="ibox-tools" v-if="$store.getters.attendance.is_open">
                    <a @click="$emit('isForm', true)" class="btn btn-primary ">
                        <i class="fa fa-plus"></i>
                        Cadastrar
                    </a>
                </div>
            </div>
            <div class="ibox-content">
                <form method="get" v-on:submit.prevent>
                    <div class="input-group">
                        <input type="text" v-on:keyup.enter="list" name="name"
                               class="form-control"
                               v-model="search.name"
                               placeholder="Localizar">
                        <div class="input-group-append">
                            <button @click="list" class="btn btn-block btn-primary">
                                <i class="fa fa-search"></i>
                                Pesquisar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="ibox-content">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                        <thead>
                        <tr>
                            <th style="width: 100px; text-align: center">Ações</th>
                            <th>Comentário</th>
                            <th class="hidden-xs hidden-sm" style="width: 150px;">Criado em</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="dataset.length > 0" :id="'tr-sd-' + item.id" v-for="item in dataset">
                            <td style="text-align: center">
                                <div class="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button"
                                            class="btn btn-default dropdown-toggle"
                                            data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                        Ações
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                        <template v-if="$store.getters.attendance.is_open">
                                            <a @click="createPendence(item)"
                                               data-toggle="modal"
                                               data-target="#modalCheckListPendencia" class="dropdown-item">Adicionar
                                                Pendência
                                            </a>
                                            <a class="dropdown-item"
                                               @click="$emit('isEditing', item)">Editar
                                            </a>
                                            <link-destroy-component
                                                :line-id="'tr-sd-' + item.id"
                                                :link=item.destroyURL()>
                                            </link-destroy-component>
                                        </template>
                                    </div>
                                </div>
                            </td>
                            <td>{{ item.commentary }}</td>
                            <td class="hidden-xs hidden-sm">{{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <span v-else>
        <div class="topbar topbar-menu topbar-menu-action">
            <div class="container-fluid">
                <div class="menu-action">
                    <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#filter" type="button">
                            <span>
                                <i class="fa fa-filter"></i>
                                Filtrar pesquisa
                            </span>
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <a v-if="$store.getters.attendance.is_open"
                       @click="$emit('isForm', true)"
                       class="btn btn-success" href="#">Cadastrar</a>
                </div>
            </div>
            <div class="container-fluid">
                <div class="filter-collapse collapse" id="filter">
                     <form method="get" v-on:submit.prevent>
                        <div class="form-group">
                            <input type="text" v-on:keyup.enter="list" name="name"
                                   class="form-control"
                                   v-model="search.tank_description"
                                   placeholder="Pesquisar por descrição">
                        </div>
                            <button @click="list" class="btn btn-block btn-success">
                                    <i class="fa fa-search"></i>
                                    Pesquisar
                                </button>
                    </form>
                </div>
            </div>
        </div>
        <div class="list-group list-group-card">
            <div class="container-fluid">
                <div v-if="dataset.length > 0" :id="'tr-scst-' + item.id" v-for="(item, index) in dataset"
                     class="card card-custom-table card-custom-collapse">
                    <div class="card-content">
                        <div class="card-body-content">
                            <div class="card-header">
                                <h6>Comentário </h6>
                            </div>
                            <div class="card-body">
                             {{ item.commentary }}
                            </div>
                        </div>
                        <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#item-5"
                                type="button">
                            <i class="fa fa-angle-down"></i>
                        </button>
                    </div>
                    <ul class="list-group list-group-table collapse" id="item-5">
                        <li class="list-group-item">
                          Criado em:  {{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}
                        </li>
                        <li class="list-group-item">
                          <a @click="createPendence(item)"
                             data-toggle="modal"
                             data-target="#modalCheckListPendencia">Adicionar
                                                    Pendência
                                                </a>
                        </li>
                        <li class="list-group-item">
                            <a @click="$emit('isEditing', item)">
                                <i class="fa fa-pencil"></i>
                                Editar
                            </a>
                        </li>
                        <li class="list-group-item">
                            <link-destroy-component
                                :dropdown="false"
                                :danger="true"
                                :line-id="'tr-scst-' + item.id"
                                :link=item.destroyURL()>
                                                    </link-destroy-component>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
        <div :class="{'modal':true,'inmodal':true, 'fade':true}" id="modalCheckListPendencia"
             tabindex="-1"
             role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <b>Nova Pendência</b>
                        {{ pendence.index }} - {{ pendence.commentary }}
                    </div>
                    <div class="modal-body">
                        <form :method="method" class="form-horizontal" name="frm_pendence_checklist"
                              autocomplete="off"
                              @submit.prevent="submitPendence">

                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label>Descrição *</label>
                                    <input class="form-control" name="description"
                                           v-model.lazy="pendence.commentary"
                                           value=""/>
                                    <div v-if="errors && errors.description" class="text-danger">{{
                                            errors.description[0]
                                        }}
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="user_id">Responsável</label>
                                    <v-select
                                        :options="usersAdmin"
                                        id="user_id"
                                        :reduce="option => option.id"
                                        label="description"
                                        placeholder="Escolha um responsável"
                                        v-model="pendence.user_id">
                                        <template slot="no-options">
                                            Pesquise por usuários
                                        </template>
                                    </v-select>


                                    <div v-if="errors && errors.user_id" class="text-danger">{{
                                            errors.user_id[0]
                                        }}
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <label>Data para entrega</label>
                                    <input type="datetime-local" name="due"
                                           class="form-control"
                                           v-model="pendence.due"/>
                                    <div v-if="errors && errors.due" class="text-danger">{{
                                            errors.due[0]
                                        }}
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <label>Status</label>
                                    <select name="status" v-model="pendence.status"
                                            class="form-control">
                                        <option v-for="(state, index) in $store.getters.pending_status"
                                                :value="index">
                                            {{ state }}
                                        </option>
                                    </select>
                                    <div v-if="errors && errors.status" class="text-danger">{{
                                            errors.status[0]
                                        }}
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <label>Observação</label>
                                    <textarea name="observation"
                                              class="form-control"
                                              v-model="pendence.observation"></textarea>
                                    <div v-if="errors && errors.observation" class="text-danger">{{
                                            errors.due[0]
                                        }}
                                    </div>
                                </div>


                            </div>

                            <div class="form-group col-md-12">
                                <button class="btn btn-primary" type="submit">
                                    <i class="fa fa-save"></i>
                                    Salvar Pendência
                                </button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import 'vue-select/dist/vue-select.css';
import moment from 'moment'
import {destroyURL} from "../../../models/helper";
import ServiceCleaningStorageTankModel from "../../../models/ServiceCleaningStorageTankModel";
import ServiceDiverseModel from "../../../models/ServiceDiverseModel";
import vSelect from 'vue-select';
import Pendency from "../../../models/PendencyModel";
import UserModel from "../../../models/UserModel";

export default {

    name: "ServiceDiverseListComponent",
    components: {vSelect},
    props: {
        id: {
            type: String,
            required: false
        },
        isForm: {
            type: Boolean,
            required: true
        },
        fields: {
            type: Object,
            required: false
        }
    },
    data() {
        return {

            loaded: true,
            errors: {},
            method: 'POST',
            itemOptions: [],
            dataset: [],
            search: {},
            usersAdmin: [],
            pendence: {}
        }
    },
    cerated() {
    },
    methods: {
        moment: moment,
        async list() {
            this.dataset = await ServiceDiverseModel.list('/', this.search);
        },
        async getUsers() {
            this.usersAdmin = await UserModel.find('', null);
        },
        createPendence(service) {
            this.pendence = service
            this.pendence.index = service.id
            this.pendence.status = 'PENDING'
            this.pendence.due = moment(this.date_end).add('2', 'days').format("YYYY-MM-DDTHH:mm")
        },
        async submitPendence() {

            let form = document.forms.namedItem('frm_pendence_checklist');
            let Data = new FormData(form);

            Data.append("user_id", this.pendence.user_id)
            Data.append("attendance_id", this.$store.getters.attendance.id);
            Data.append("item_id", this.pendence.id);

            let url = Pendency._module();

            return this.savePendence(url, Data);
        },
        savePendence(url, data) {
            this.errors = {};
            block()

            axios.post(url, data)
                .then(() => {
                    showMessage('s', 'Salvo com sucesso');
                    this.$emit('isForm', false)

                })
                .catch(error => {

                    if (error.response.status === 500) {
                        showMessage('e', 'Não foi possível completar requisição');
                    }


                    if (error.response.status === 401) {
                        showMessage('w', error.response.data.server_error);
                    }

                    if (error.response.status === 400) {
                        this.errors = error.response.data.errors || {};
                        showMessage('w', 'Preencha todos os campos obrigatórios.');
                    }
                })
                .finally(() => unBlock())
        },

    },
    mounted() {
        this.list()
        this.getUsers();
    }
}
</script>
<style scoped>
</style>
