import {fromJson, searchOffline, URL_BASE} from "./helper";
import store from "../store";


class FuelsModel {


    constructor() {

        this.description = null;

    }


    static _module() {
        return URL_BASE + "/fuels";
    }


    static fromJson(object) {
        return fromJson((new FuelsModel()), object);
    }

    static async find(search) {
        let url = this._module() + "/find";
        var collection = new Array()
        if (!navigator.onLine){
            return await searchOffline('fuels', store.getters.attendance.client.id);
        }
        await axios.get(url, {
            params: {
                search: search,
                client_id: store.getters.attendance.client.id
            }
        })
            .then((response) => {
                collection = response.data.map(function (model) {
                    return {
                        id: model.id,
                        description: model.description,
                    }
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }

}

export default FuelsModel;
