<template>
    <div>
        <pending-form-component
            @isForm="isForm"
            :item="item"
            v-if="form" />
        <pending-list-component
            :isForm="form"
            @isForm="isForm"
            @isEditing="isEditing"
            v-else/>
    </div>
</template>

<script>
    export default {

        name: "PendingComponent",
        components: {},
        data() {
            return {
                form: false,
                item: null,
            }
        },
        methods: {
            isForm(value, clear = true) {
                this.form = value
                if (clear) this.item = null;
            },
            isEditing(value) {
                this.item = value
                this.isForm(true, false);
            }
        },
        mounted() {

        }
    }


</script>

<style scoped>

</style>
