<template>
    <div  v-if="!$store.getters.isMobile" class="row">
        <div  class="col-lg-12">
            <div class="ibox">

                <div class="ibox-title">
                    <h4>Listagem de arquivos</h4>
                    <div class="ibox-tools" v-if="$store.getters.attendance.is_open">
                       <!-- <a @click="$emit('isForm', true)" class="btn btn-primary ">
                            <i class="fa fa-plus"></i> Cadastrar
                        </a>-->
                    </div>
                </div>

                <div class="ibox-content">

                    <div class="m-b-lg">
                        <form method="get" v-on:submit.prevent>
                            <div class="row align-items-center">
                                <div class="col-md-10">
                                    <input type="text" v-on:keyup.enter="getArchives" name="search"
                                           class="form-control"
                                           v-model="search.search"
                                           placeholder="Localizar">
                                </div>
                                <div class="col-md-2">
                                    <button @click="getArchives" class="btn btn-block form-control">
                                        <i class="fa fa-search"></i> Pesquisar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-row" id="archives">
                            <div v-for="file in archives" :class="['col-sm-12', 'col-md-4', 'col-lg-3', 'document_files document_div_'+ file.id]"
                                 :data-name="file.description">
                                <div class="payment-card text-center">
                                    <i :class="['fa', file.icon , 'payment-icon-big', 'text-danger', 'fa-3x'] "></i>
                                    <h5 class="mt-2 description">
                                         {{file.description}}
                                    </h5>
                                    <div class="row mt-2 mb-2">
                                        <div class="col-sm-12 col-md-6 text-left">
                                            <a target="_blank" :href=" file.url_download  "
                                               class="btn btn-outline-primary  d-inline-flex">Acessar </a>
                                            <!--<button v-if="$store.getters.attendance.is_open" v-on:click="deleteArchive(file.id)" title="Excluir"
                                                    class="btn delete-document-btn btn-outline-danger d-inline-flex">
                                                Excluir
                                            </button>-->
                                        </div>
                                        <div class="col-sm-12 col-md-6 text-right">
                                            <small>
                                                <strong>Enviado por:</strong> {{ file.user_creator }} <br>
                                                <strong>Em:</strong> {{ file.created_at }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <span v-else>
        <div class="topbar topbar-menu topbar-menu-action">
            <div class="container-fluid">
                <div class="menu-action">
                    <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#filter" type="button" style="width: 100%">
                            <span>
                                <i class="fa fa-filter"></i>
                                Filtrar pesquisa
                            </span>
                        <i class="fa fa-angle-down"></i>
                    </button>
<!--                    <a v-if="$store.getters.attendance.is_open"
                       @click="$emit('isForm', true)"
                       class="btn btn-success" href="#">Cadastrar</a>-->
                </div>
            </div>
            <div class="container-fluid">
                <div class="filter-collapse collapse" id="filter">
                     <form method="get" v-on:submit.prevent>
                        <div class="form-group">
                            <input type="text" v-on:keyup.enter="getArchives" name="name"
                                   class="form-control"
                                   v-model="search.search"
                                   placeholder="Localizar">
                        </div>
                            <button @click="getArchives" class="btn btn-block btn-success">
                                    <i class="fa fa-search"></i>
                                    Pesquisar
                                </button>
                    </form>
                </div>
            </div>
        </div>
        <div class="list-group list-group-card">
            <div class="container-fluid">
                <div v-for="file in archives" :id="'tr-scst-' + file.id" :class="['document_files document_div_'+ file.id]"
                     class="card card-custom-table card-custom-collapse">
                    <div class="card-content">
                        <div class="card-body-content">
                            <div class="card-body">
                                <i :class="['fa', 'fa-file','text-default'] "></i>
                             {{ file.description }}
                                <hr>
                                  <a target="_blank" :href=" file.url_download  "
                                     class="btn btn-outline-info d-inline-flex">Acessar </a>
                            </div>

                        </div>
                        <button class="btn btn-block btn-link" data-toggle="collapse" :data-target="'#item-'+file.name"
                                type="button">
                            <i class="fa fa-angle-down"></i>
                        </button>
                    </div>
                    <ul class="list-group list-group-table collapse" :id="'item-'+file.name">
                        <li class="list-group-item">
                          Criado por: {{ file.user_creator }}
                        </li>

                        <li class="list-group-item">
                          Enviado em:  {{ moment(file.created_at).format("DD/MM/YYYY HH:mm") }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </span>
</template>

<script>

    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select';
    import moment from 'moment'
    import ArchiveModel from "../../models/ArchiveModel";

    export default {
        components: {vSelect},
        props: {
            id: {
                type: String,
                required: false
            },
            isForm: {
                type: Boolean,
                required: true
            },
            fields: {
                type: Object,
                required: false
            }
        },
        data() {
            return {

                loaded: true,
                errors: {},
                method: 'POST',
                itemOptions: [],
                selected: 0,
                dataset: [],
                search: {},
                archives: []
            }
        },
        created() {
            //this.getArchives()
        },
        methods: {
            moment: moment,
            async list() {
                let app = this
                this.dataset = await ArchiveModel.list('/', this.search);
                this.archives = []
                this.dataset.map(document => {
                    this.addFile(document)
                })

            },
            async getArchives() {

                this.dataset = await ArchiveModel.list('/', this.search);
                this.archives = []
                this.dataset.map(document => {
                    this.addFile(document)
                })

            },

            async deleteArchive(file_id) {

                if (confirm("Você tem certeza que deseja apagar esse documento?")) {

                    this.dataset = await ArchiveModel.destroyURL(file_id);
                    this.list()

                }
                return false;
            },

            addFile(document) {



                let archive = {
                    ...document,
                    "icon": this.returnIconByExtension(document.extension),
                    "description":  document.name,
                    "user_creator": document.creator,
                }

                this.archives.push(archive)

            },

            returnIconByExtension(extension) {

                let icon = '';

                switch (extension) {
                    case 'doc':
                    case 'docx':
                        icon = 'fa-file-word-o'
                        break;

                    case 'pdf':
                        icon = 'fa-file-pdf-o'
                        break;

                    case 'text':
                        icon = 'fa-file-alt-o'
                        break;

                    case 'gif':
                    case 'jpeg':
                    case 'jpg':
                    case 'png':
                        icon = 'fa-file-image-o'
                        break;

                    case 'ppt':
                    case 'pptx':
                        icon = 'fa-file-powerpoint-o'
                        break;

                    case 'xls':
                    case 'xlsx':
                        icon = 'fa-file-excel-o'
                        break;

                    case 'gz':
                    case 'rar':
                    case 'zip':
                        icon = 'fa-file-archive-o'
                        break;

                    case 'mp3':
                    case 'aac':
                    case 'ogg':
                        icon = 'fa-file-audio-o'
                        break;

                    case 'flv':
                    case 'avi':
                    case 'mp4':
                    case 'wmv':
                    case 'mkv':
                        icon = 'fa-file-video-o'
                        break;

                    case 'css':
                    case 'html':
                    case 'js':
                        icon = 'fa-file-code-o'
                        break;
                    default:
                        icon = 'fa-file-o'
                }
                return icon
            },
        },
        mounted() {
            this.list()
        }
    }

</script>

<style scoped>
    .vs__search {
        height: 60px;
    }
</style>
