<template>
    <div>
        <div class="topbar topbar-menu topbar-menu-action">
            <div class="container-fluid">
                <div class="menu-action">
                    <button class="btn btn-block btn-link" data-toggle="collapse" data-target="#filter" type="button">
                    <span>
                        <i class="fa fa-filter"></i>
                        Filtrar pesquisa
                    </span>
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <a class="btn btn-success" @click="$emit('attendance-new')">Novo atendimento</a>
                </div>
            </div>
            <div class="container-fluid">
                <div class="filter-collapse collapse" id="filter">
                </div>
            </div>
        </div>
        <div class="wrapper wrapper-content">
            <div class="list-group list-group-card" v-for="attendance in attendances">
                <div class="container-fluid" id="attendances-list">
                    <div class="card card-custom-table">
                        <div class="card-header">
                            <h6>Atendimento #{{attendance._id}}</h6>
                            <button class="btn btn-link" data-toggle="collapse" :href="'#menu-'+attendance._id"
                                    type="button">
                                <i class="fa fa-ellipsis-v"></i>
                            </button>
                        </div>
                        <div class="card-body">
                            <span class='badge badge-danger'>Offline</span><br>
                            Cliente: <strong>{{attendance.client_name.name || ''}}</strong> <br>
                            Situação: <strong>{{'em andamento'}}</strong> <br>
                            Início: <strong>{{attendance.date_hour_start || (new Date()).toDateString()}}</strong> <br>
                        </div>
                        <div class="card-collapse collapse" :id="'menu-'+attendance._id">
                            <ul class="list-group list-group-collapse">
                                <li class="list-group-item">
                                    <a href=""><i class="fa fa-check-double"></i> Pendências</a>
                                </li>
                                <li class="list-group-item">
                                    <a href=""><i class="fa fa-clock"></i> Histórico</a>
                                </li>
                                <li class="list-group-item">
                                    <a href="#" @click="$emit('attendance-show', attendance)"><i
                                        class="fa fa-file-alt"></i> Detalhes</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    name: "AttendanceListComponent",
    components: {},
    data() {
        return {
            attendances: []
        }
    },
    methods: {
        async getClients() {
            let clients = [];
            var db = new PouchDB('ga-db');
            await db.allDocs({include_docs: true, descending: true, key: 'clients'}, function (err, doc) {
                clients = doc.rows[0].doc.data
            });
            return clients;
        },

        async getAttendances() {
            let attendances = [];
            var dbAttendance = new PouchDB('ga-attendances');
            await dbAttendance.allDocs({include_docs: true, descending: true}, function (err, doc) {
                doc.rows.forEach((obj) => {
                    attendances.push(obj.doc);
                })
            });

            console.log(attendances)

            return attendances;
        },

        async listAttendances() {

            let attendances = await this.getAttendances()
            let clients = await this.getClients()

            attendances = attendances.map(function (attendance) {
                attendance["client_name"] = Object.values(clients).find(x => x.id == attendance.client_id)
                return attendance;
            })

            console.log(attendances)

            attendances.forEach((attendance) => {
                this.attendances.push(attendance)
            })
        }
    },
    mounted() {
        let client_id = null

        function deleteAttendance(id) {
            if (confirm('Deletar attendimento')) {
                var dbAttendance = new PouchDB('ga-attendances');
                dbAttendance.get(id).then(function (doc) {
                    return dbAttendance.remove(doc);
                }).then(function (result) {
                    alert('Deletado com sucesso!')
                    setTimeout(function () {
                        top.location.href = '/panel/attendances'
                    }, 500)
                }).catch(function (err) {
                    console.log(err);
                });
            }
        }

        if (!navigator.onLine) {

            this.listAttendances()

        }
    }
}


</script>
<style scoped>

</style>
