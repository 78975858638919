import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({

        state: {
            user: {},
            attendance: '',
            laboratory_test: [],
            visual_aspect: [],
            types: [],
            pending_status: [],
            histories: [],
            url: '',
            is_mobile: false,
        },

        getters: {

            attendance(state) {

                return state.attendance
            },
            user(state) {

                return state.user
            },

            laboratory_test(state) {

                return state.laboratory_test
            },
            visual_aspect(state) {

                return state.visual_aspect
            },
            types(state) {

                return state.types
            },
            pending_status(state) {

                return state.pending_status
            },
            histories(state) {

                return state.histories
            },

            url(state) {

                return state.url
            },

            isMobile(state) {

                return state.is_mobile
            },


            isLogado(state) {
                return id => (state.user.id == id)
            }

        },

        actions: {
            allRouters(context) {
                //context.commit("routers", response.data.routers)
            },
        },

        mutations: {
            routers(state, data) {
                return state.routers = data
            },
            user(state, data) {
                return state.user = data
            },
            attendance(state, data) {
                return state.attendance = data
            },
            laboratory_test(state, data) {
                return state.laboratory_test = data
            },
            visual_aspect(state, data) {
                return state.visual_aspect = data
            },
            types(state, data) {
                return state.types = data
            },
            pending_status(state, data) {
                return state.pending_status = data
            },
            histories(state, data) {
                return state.histories = data
            },
            url(state, data) {
                return state.url = data
            },
            is_mobile(state, data) {
                return state.is_mobile = data
            }


        }
    }
)


export default store
