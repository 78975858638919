<template>
    <div>
        <div v-if="tabs.service1">
            <service-pump-standardisation-form-component
                @isForm="isForm"
                :item="service1.item"
                v-if="service1.form">
            </service-pump-standardisation-form-component>
            <service-pump-standardisation-list-component
                ref="service3_list"
                :isForm="service1.form"
                @isForm="isForm"
                @isEditing="isEditing"
                v-else>
            </service-pump-standardisation-list-component>
        </div>
        <div v-if="tabs.service2">
            <service-fuel-stock-audits-form-component
                @isForm="isForm"
                :item="service2.item"
                v-if="service2.form">
            </service-fuel-stock-audits-form-component>
            <service-fuel-stock-audits-list-component
                ref="service3_list"
                :isForm="service2.form"
                @isForm="isForm"
                @isEditing="isEditing"
                v-else>
            </service-fuel-stock-audits-list-component>
        </div>
        <div v-if="tabs.service3">
            <service-fuel-quality-form-component
                @isForm="isForm"
                :item="service3.item"
                v-if="service3.form">
            </service-fuel-quality-form-component>
            <service-fuel-quality-list-component
                ref="service3_list"
                :isForm="service3.form"
                @isForm="isForm"
                @isEditing="isEditing"
                v-else>
            </service-fuel-quality-list-component>
        </div>
        <div v-if="tabs.service4">
            <service-cleaning-storage-tank-form-component
                @isForm="isForm"
                :item="service4.item"
                v-if="service4.form">
            </service-cleaning-storage-tank-form-component>
            <service-cleaning-storage-tank-list-component
                ref="service4_list"
                :isForm="service4.form"
                @isForm="isForm"
                @isEditing="isEditing"
                v-else>
            </service-cleaning-storage-tank-list-component>
        </div>
        <div v-if="tabs.service5">
            <service-dialysi-form-component
                @isForm="isForm"
                :item="service5.item"
                v-if="service5.form">
            </service-dialysi-form-component>
            <service-dialysi-list-component
                ref="service4_list"
                :isForm="service5.form"
                @isForm="isForm"
                @isEditing="isEditing"
                v-else>
            </service-dialysi-list-component>
        </div>
        <div v-if="tabs.service6">
            <service-diverse-form-component
                @isForm="isForm"
                :item="service6.item"
                v-if="service6.form">
            </service-diverse-form-component>
            <service-diverse-list-component
                ref="service6_list"
                :isForm="service6.form"
                @isForm="isForm"
                @isEditing="isEditing"
                v-else>
            </service-diverse-list-component>
        </div>
        <div v-if="tabs.service7">
            <service-checklist-component>
            </service-checklist-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: "ServiceComponent",
    components: {},
    props: {
        service:{
            default: 'service0',
        },
    },
    data() {
        return {
            service1: {
                form: false,
                item: null,
            },
            service2: {
                form: false,
                item: null,
            },
            service3: {
                form: false,
                item: null,
            },
            service4: {
                form: false,
                item: null,
            },
            service5: {
                form: false,
                item: null,
            },
            service6: {
                form: false,
                item: null,
            },
            service7: {
                form: false,
                item: null,
            },
            tabs: {
                service1: false,
                service2: false,
                service3: false,
                service4: false,
                service5: false,
                service6: false,
                service7: false,
            }
        }
    },
    beforeCreate() {
    },

    watch: {
        service(val) {
            this.tabAction(this.service);
        }
    },
    methods: {
        moment: moment,
        isForm(value, clear = true) {
            this[this.service].form = value
            if (clear) this[this.service].item = null;
        },
        isEditing(value) {
            this[this.service].item = value
            this.isForm(true, false);
        },
        tabAction(tab) {
            this.tabClear()
            this.tabs[tab] = true
        },
        tabClear() {
            this.tabs = {
                service1: false,
                service2: false,
                service3: false,
                service4: false,
                service5: false,
                service6: false,
                service7: false,
            }
        }
    },
    mounted() {
        this.tabAction(this.service);
    }
}
</script>
<style scoped>
</style>
