import {fromJson, getCollectionOffline, URL_BASE} from "./helper";
import store from "../store";


class ServicePumpStandardisationModel {


    constructor() {

        this.id = null;
        this.fuel_pump = null;
        this.terminant = null
        this.initial_result = null
        this.is_regulated = 0
        this.result_after_adjustment = null
        this.sealed = 0
        this.seal_number = null
        this.measurer_changed = 0
        this.images = []
        this.image_description = null
        this.created_at = null

    }


    static _module() {
        return URL_BASE + "/service_pumps_standardisations";
    }

    static fromJson(object) {
        return fromJson((new ServicePumpStandardisationModel()), object);
    }

    destroyURL() {
        return ServicePumpStandardisationModel._module() + "/" + this.id + "/destroy"
    }


    static async list(endpoint = '/', data = {}) {
        data.attendance_id = store.getters.attendance.id

        if (!navigator.onLine){
            return getCollectionOffline('pump_standardisation',data.attendance_id, this)
        }

        let url = this._module() + endpoint;
        var collection = new Array()
        await axios.get(url, {params: data})
            .then((response) => {
                collection = response.data.data.map(function (object) {
                    return ServicePumpStandardisationModel.fromJson(object)
                })

            })
            .catch(error => {
                console.error(error)
                showMessage('w', "Não foi possível carregar");

            })
            .finally(() => true)

        return collection
    }
}

export default ServicePumpStandardisationModel;
